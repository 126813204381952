import {useEffect, useState} from 'react';
import {spinnerIcon, lockIcon, exclamationIcon} from '../helpers/icons';
import { homePath, signPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../helpers/validation';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase/firebase';
import { useStore } from '../store/store';



const SignIn = () => {

  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  let navigate = useNavigate();
  const setUser = useStore(state => state.setUser);
  const email = useStore(state => state.email);

  useEffect(() => {
    const handleCleanup = () => {
      setUser('');
    }
    return handleCleanup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validateSignInForm = () => {
    let validated = false
    if (password.length < 8) {
      setErrorMessage('Your password must be at least 8 characters');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = () => {
    if (!validateSignInForm()) return;
    setButtonLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // amplitude.getInstance('client').logEvent('signIn', { email });
        const user = userCredential.user;
        setUser(user);
        setErrorMessage('');
        navigate(homePath);
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;
        errorMessage = returnErrorMessage({errorCode, errorMessage});
        console.log(errorCode, errorMessage)
        setErrorMessage(errorMessage);
        setButtonLoading(false);
      });
  }

  const returnErrorMessage = ({errorCode, errorMessage}) => {
    if (errorCode === 'auth/user-not-found') {
      return 'There is no account with that email address.'
    } else {
      return errorMessage;
    }
  }

  return (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
    
          </p>
          <p className="mt-2 text-center text-sm text-gray-600">
            <div>{email}</div>
            <div className="mt-2">
              <span onClick={() => { navigate(signPath) }} className="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
                Sign in to a different account
              </span>.
            </div>

          </p>
        </div>
        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => { setPassword(e.target.value) }}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={buttonLoading}
              onClick={(e) => { handleClick() }}
            >
              {buttonLoading ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {lockIcon}
                </span>)
              }
              {buttonLoading ? '' : 'Sign in'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </form>
      </div>
    </div>  )
}

export default SignIn;