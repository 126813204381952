import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";
import pluralize from 'pluralize';

const TakeAssessmentInstructions = ({ 
  handleClick, 
  errorMessage, 
  buttonLoading, 
  jobTitle, 
  orgName, 
  numQuestions, 
  minutes 
}) => {
  return (
    <div className="bg-gray-50 min-h-screen-adj">

      <div className="text-center text-gray-800 py-10 px-6">
        <div className="text-gray-500">Take this assessment for the </div>
        <div className="text-3xl font-bold mt-0">{jobTitle}</div>
        <div className="text-gray-500">role at</div>
        <div className="text-xl font-bold">{orgName}</div>
      </div>

      <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="mt-8 space-y-4">

            {/* Name */}
            <ul className="text-gray-700 text-md p-6 list-disc space-y-4">
              <li>This assessment has <b>{numQuestions}</b> {pluralize('question',numQuestions)}.</li>
              <li>You will have <b>{Math.floor(minutes)}</b> {pluralize('minutes', Math.floor(minutes))} to complete it.</li>
              <li>Once you begin, do <b>not</b> navigate away from this page.</li>
              <li>You must complete this assessment in one sitting.  You will <b>not</b> be able to save progress and return later.</li>
            </ul>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => { handleClick() }}
            // disabled={orgIsBeingCreated}
            >
              {buttonLoading ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {buttonLoading ? '' : 'Next'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

        </div>
      </div>
    </div>
    </div>
  )
}

export default TakeAssessmentInstructions;