import {useState, useEffect, useCallback} from 'react';
import { useStore } from '../store/store';
import { getTemplateAssessment, createAssessmentFromTemplate } from '../actions/templateAssessments';
import {useParams} from 'react-router-dom';
import {spinnerIcon, exclamationIcon} from '../helpers/icons';
import { ToastContainer } from 'react-toastify';
import LoadingPage from './LoadingPage';
import QuestionList from './QuestionList';
import Question from './Question';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import {sortQuestions} from '../helpers/sorting';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {returnDashboardPath, returnAssessmentPath} from '../helpers/urls';

const TemplateAssessmentPage = (props) => {

  const [jobTitle, setJobTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const user = useStore(state => state.user);
  let {templateAssessmentId} = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [loadAssessmentPage, setLoadAssessmentPage] = useState(0)
  const [questionOrder, setQuestionOrder] = useState();
  const draggingQuestion = useStore(state => state.draggingQuestion);
  let navigate = useNavigate();
  const [useAssessmentButtonLoading, setUseAssessmentButtonLoading] = useState(false);


  const numQuestions = questions.length;
  const returnDuration = (questions) => {
    let duration = 0;
    questions.map((question) => question.seconds).forEach((seconds) => {
      duration = duration + seconds;
    });
    return duration;
  };
  const duration = returnDuration(questions);
  const minutes = duration / 60;

  useEffect(() => {
    if (loadAssessmentPage === null || loadAssessmentPage === undefined) return;
    setPageLoading(true);
    getTemplateAssessment({user, templateAssessmentId}).then(({jobTitle, questions, questionOrder}) => {
      setJobTitle(jobTitle);
      let sortedQuestions = sortQuestions({questions, questionOrder});
      setQuestions(sortedQuestions);
      setQuestionOrder(questionOrder ? questionOrder : Object.values(questions).map(({questionId}) => questionId))
      setPageLoading(false);
    }).catch((error) => {
      setPageLoading(false);
      console.error(error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAssessmentPage])

  const returnAllAssessmentSkills = () => {
    let allAssessmentSkills = [];
    Object.values(questions).forEach((question) => {
      let skills = question?.skills ? question?.skills : [];
      allAssessmentSkills = [...allAssessmentSkills, ...skills];
    });
    let allAssessmentSkillsMap = {}
    let withouDuplicates = [];
    allAssessmentSkills.forEach((skill) => {
      if (!(skill in allAssessmentSkillsMap)) {
        allAssessmentSkillsMap[skill] = skill;
        withouDuplicates.push(skill);
      }
    })
    return withouDuplicates;
  }


  const moveQuestionListItem = useCallback(
    async (dragIndex, hoverIndex) => {
      // console.log({dragIndex, hoverIndex});
      const dragItem = questions[dragIndex]
      const hoverItem = questions[hoverIndex]
      // Swap places of dragItem and hoverItem in the questions array
      const returnNewQuestions = (questions) => {
        const updatedQuestions = [...questions]
        updatedQuestions[dragIndex] = hoverItem
        updatedQuestions[hoverIndex] = dragItem
        return updatedQuestions
      };
      // let oldQuestions = questions;
      const newQuestions = returnNewQuestions(questions);
      const newQuestionOrder = newQuestions.map(({questionId}) => questionId);
      if (dragIndex === hoverIndex) return;
      setQuestions(newQuestions);
      console.log({ questionOrder, newQuestionOrder })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [questions]
  )

  useEffect( () => {
    const updateAssessmentWhenQuestionOrderChanges = async () => {
      try {
        const newQuestionOrder = questions.map(({ questionId }) => questionId);
        if (!!questionOrder && !_.isEqual(questionOrder, newQuestionOrder) && !draggingQuestion) {
          console.log('1')
        }
      } catch (error) {
        setLoadAssessmentPage(true);
      }
    }
    updateAssessmentWhenQuestionOrderChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingQuestion])

  const handleBack = () => {
    navigate(returnDashboardPath({openCreateModal: true}))
  }

  const handleCreateAssessmentFromTemplate = async () => {
    setUseAssessmentButtonLoading(true);
    await createAssessmentFromTemplate({ user, templateAssessmentId }).then((data) => {
      // amplitude.getInstance('client').logEvent('updateQuestion', { questionId, questionText });
      setErrorMessage('');
      setUseAssessmentButtonLoading(false);
      navigate(returnAssessmentPath({ assessmentId: data.assessmentId }))
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setUseAssessmentButtonLoading(false);
    })
  }


  return pageLoading ? <LoadingPage /> : (
    <DndProvider backend={HTML5Backend}>

    <div >
      <div className=" bg-gray-50 min-h-screen-adj">
        <header className="shadow">
          <div className="py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{jobTitle}</h1>
              <div className="text-xs text-gray-500">{numQuestions} questions, {Math.round(minutes)} minutes</div>
            </div>
            <div>
              <div className="flex justify-end">
                <button
                  className="mr-2 rounded-lg border border-gray-500 bg-white text-gray-500 py-1 px-2 text-sm hover:bg-gray-200 hover:text-gray-600 flex justify-center"
                onClick={() => { handleBack() }}
                >
                  <span>Back</span>
                </button>
                {<button
                  className="rounded-lg border border-blue-500 bg-blue-100 text-blue-500 py-1 px-2 text-sm hover:bg-blue-200 hover:text-blue-600 flex justify-center"
                  onClick={() => { handleCreateAssessmentFromTemplate() }}
                  disabled={useAssessmentButtonLoading}
                >
                  {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {chevronRightIcon}
                  </span> */}
                  <span className={useAssessmentButtonLoading ? "invisible" : ''}>Use this assessment</span>
                  {!useAssessmentButtonLoading ? '' : <span className="absolute m-auto text-center">{spinnerIcon}</span>}
                </button>}

              </div>
              
            </div>
          </div>
        </header>
        <main>
                  <QuestionList dragAndDrop={false}>
                  {questions.map((question, index) => {
                    const { questionId, queryText, skills, seconds, choices, choicesCollapsed } = question;
                      return (
                        <Question
                          key={questionId}
                          questionId={questionId}
                          queryText={queryText}
                          skills={skills}
                          seconds={seconds}
                          choices={choices}
                          choicesCollapsed={choicesCollapsed}
                          setLoadAssessmentPage={setLoadAssessmentPage}
                          moveListItem={moveQuestionListItem}
                          index={index}
                          returnAllAssessmentSkills={returnAllAssessmentSkills}
                          dragAndDrop={false}
                          template={true}
                        />
                    )}
                  )}
                  </QuestionList>
        </main>
      </div>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
    </DndProvider>

  )
}

export default TemplateAssessmentPage;