import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";

const TakeAssessmentEnterName = ({name, setName, email, setEmail, handleClick, errorMessage, buttonLoading, jobTitle, orgName}) => {
  return (
    <div className="bg-gray-50 min-h-screen-adj">

      <div className="text-center text-gray-800 py-10 px-6">
        <div className="text-gray-500">Take this assessment for the </div>
        <div className="text-3xl font-bold mt-0">{jobTitle}</div>
        <div className="text-gray-500">role at</div>
        <div className="text-xl font-bold">{orgName}</div>
      </div>

      <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="mt-8 space-y-4">

            {/* Name */}
            <div>
              <label htmlFor="name" className="text-sm font-semibold">Full name</label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Bruce Wayne"
                value={name}
                onChange={(e) => { setName(e.target.value) }}
              />
            </div>
            

            {/* Email */}
            <div>
              <label htmlFor="email" className="text-sm font-semibold">Email</label>
              <input
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="bruce@wayneindustries.com"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => { handleClick() }}
            // disabled={orgIsBeingCreated}
            >
              {buttonLoading ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {buttonLoading ? '' : `Start assessment (timer will begin)`}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

        </div>
      </div>
    </div>
  )
}

export default TakeAssessmentEnterName;