import { clockIcon, trashIcon, pencilAltIcon, smallCheckIcon, xIcon } from '../helpers/icons';



const QuestionFromAttempt = ({question, index}) => {

  const {questionId, queryText, choices, seconds, skills, selected} = question;

  return (
    <div className="flex">
      <div className="mt-2 pt-1 mr-2 w-4 font-semibold rounded-full">{index + 1}</div> 

        <div
          key={questionId}
          className={`rounded w-full m-2 p-2 flex bg-white shadow`}
        >
          <div className="w-full">
            <div className="font-light text-sm flex justify-between w-full display-linebreak border-b pb-4 mb-4">
              <div>{queryText}</div>
            </div>
            {!choices || choices.length === 0 ? '' : choices.map(({ choiceId, choiceText, correct }) => {
              return (
                <div key={choiceId} className="flex items-center my-2">
                  {/* <input type="checkbox" checked={correct} onChange={() => { return }} /> */}
                  <div className="pr-2">
                    {correct ? <span className="text-blue-500">{smallCheckIcon}</span> : ''}
                    {!correct && selected === choiceId ? <span className="text-red-500">{xIcon}</span> : ''}
                    {!correct && selected !== choiceId ? <span className="invisible">{smallCheckIcon}</span> : ''}
                  </div>
                  <div
                    className={`rounded-full h-4 w-4 border-blue-500 border-2 ${selected === choiceId ? 'bg-blue-500' : ''} text-white p-1`}
                  />
                  <label className="mx-2 text-sm text-gray-700">{choiceText}</label>
                </div>
              )
            })}
            <div className="flex items-center my-1 border-t pt-2">
              <span className="mx-2 flex items-center text-gray-500 border p-1 rounded">
                {clockIcon}
                <span className="text-xs  ml-1">{seconds} sec. limit</span>
              </span>
              {skills.map((skill) => {
                return (
                  <div
                    className="text-xs inline-block py-1 px-2.5 mr-1 leading-none text-center font-semibold bg-blue-100 text-blue-600 rounded-full"
                    key={skill}
                  >
                    {skill}
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </div>
  )
}

export default QuestionFromAttempt;