import {useState} from 'react';
import { thumbsUpIcon, thumbsDownIcon, darkThumbsUpIcon, darkThumbsDownIcon, largeClipboardCheckIcon } from '../helpers/icons';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from '../store/store';
import {saveDecision} from '../actions/attempts';
import { returnAttemptPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import Tooltip from './Tooltip'

const AttemptsTableRow = ({ name, email, score, dateCreated, decision, attemptId, assessmentId, handleSaveDecision}, index) => {

  // const [decision, setDecision] = useState(assessmentDecision);
  const [decisionLoading, setDecisionLoading] = useState(false);
  const user = useStore(state => state.user);
  const notify = (text) => toast(text);
  let navigate = useNavigate();


  const handleToggleDecision = (newChoice) => {
    let newDecision = decision === newChoice ? '' : newChoice;
    setDecisionLoading(true);
    saveDecision({user, attemptId, decision: newDecision}).then(() => {
      handleSaveDecision({ attemptId, decision: newDecision })
      // setDecision(newDecision);
      setDecisionLoading(false);
    }).catch((error) => {
      console.error(error);
      setDecisionLoading(false);
    })
  }

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(email);
    notify('Copied email!');
  }

  const handleViewAttempt = () => {
    navigate(returnAttemptPath({attemptId}))
  }

  return (
    <tr key={index}>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-500">{email}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{score}%</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{moment(dateCreated).format("MMM Do 'YY")}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center w-full">
          <div className="ml-4 w-full">
            <div className="text-sm font-medium text-gray-900 w-full text-left">
              {decision !== 'yes' ? '' : <span className="text-blue-500 font-semibold">Yes!</span>}
              {decision !== 'no' ? '' : <span className="text-red-500 font-semibold">No</span>}
            </div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="ml-4 flex items-center">
          <button 
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleToggleDecision('yes') }}
            disabled={decisionLoading}
          >
              {decision === 'yes' ? darkThumbsUpIcon : thumbsUpIcon}
          </button>
          <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleToggleDecision('no') }}
            disabled={decisionLoading}
          >
            {decision === 'no' ? darkThumbsDownIcon : thumbsDownIcon}
          </button>
          {/* <button 
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleCopyEmail()}}
          >{mailIcon}</button> */}
          <Tooltip content="View attempt">
            <button
            className="p-1 hover:bg-gray-100 rounded-lg flex items-center"
            onClick={() => { handleViewAttempt() }}
            >
              <span>{largeClipboardCheckIcon}</span>
              <span className="text-sm text-gray-700 ml-2">View attempt</span>
            </button>
          </Tooltip>
          {/* <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleCopyAssessmentLink() }}
          >{largeClipboardIcon}</button> */}
        </div>
      </td>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
    </tr>
  )
}

export default AttemptsTableRow;