import { Route, Routes, Navigate } from 'react-router-dom';
import SignIn from '../components/SignIn';
import SignInOrUp from '../components/SignInOrUp';
import SignUp from '../components/SignUp';
import AccountPage from '../components/AccountPage';
import AttemptsDashboard from '../components/AttemptsDashboard';
import NavRoute from './NavRoute';
import EditAssessmentPage from '../components/EditAssessmentPage';
import TakeAssessmentPage from '../components/TakeAssessmentPage';
import FallbackPage from '../components/FallbackPage';
import TemplateAssessmentPage from '../components/TemplateAssessmentPage';
import AssessmentsPage from '../components/AssessmentsPage';
import ViewAttemptPage from '../components/ViewAttemptPage';


const AppRouter = () => {
  return (
    <Routes>
      <Route path="/sign" element={<NavRoute><SignInOrUp /></NavRoute>} />
      <Route path="/signin" element={<NavRoute><SignIn /></NavRoute>} />
      <Route path="/signup" element={<NavRoute><SignUp /></NavRoute>} />
      <Route path="/account" element={<NavRoute authRequired={true}><AccountPage /></NavRoute>} />
      <Route path="/assessments/:assessmentId" element={<NavRoute authRequired={true}><EditAssessmentPage/></NavRoute>} />
      <Route path="/takeassessment/:assessmentId" element={<NavRoute><TakeAssessmentPage /></NavRoute>} />
      <Route path="/templateassessments/:templateAssessmentId" element={<NavRoute><TemplateAssessmentPage /></NavRoute>} />
      <Route path="/dashboard" element={<NavRoute authRequired={true}><AttemptsDashboard /></NavRoute>} />
      <Route path="/assessments" element={<NavRoute authRequired={true}><AssessmentsPage /></NavRoute>} />
      <Route path="/attempts" element={<NavRoute authRequired={true}><AttemptsDashboard /></NavRoute>} />
      <Route path="/attempts/:attemptId" element={<NavRoute authRequired={true}><ViewAttemptPage /></NavRoute>} />

      {/* <Route path="/*" element={<NavRoute authRequired={true}><AttemptsDashboard /></NavRoute>}> */}
      <Route path="/*" element={<Navigate to="/assessments" />}>
        <Route index element={<NavRoute><FallbackPage /></NavRoute>} />
        <Route path="" element={<Navigate to="/assessments" />} />
        {/* <Route path="" element={<NavRoute authRequired={true}><AttemptsDashboard /></NavRoute>} /> */}
      </Route>
    </Routes>
  )
}

export default AppRouter;