import { apiUrl } from '../helpers/urls';

export const getTemplateAssessmentsList = async ({ user }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/templateassessments/list`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const saveTemplateAssessment = async ({ user, assessmentId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/templateassessments`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assessmentId })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const getTemplateAssessment = async ({ user, templateAssessmentId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/templateassessments/${templateAssessmentId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const createAssessmentFromTemplate = async ({user, templateAssessmentId}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/createassessmentfromtemplate`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateAssessmentId })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}