import CreatableSelect from 'react-select/creatable'


const SkillSelect = ({ 
  handleCreateSkill, 
  handleChangeSkills, /* skillsArray, */ 
  skills,
  returnAllAssessmentSkills
}) => {

  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]

  // const options = skillsArray.map((skill) => {
  //   return {
  //     value: skill?.skillId,
  //     label: skill?.skillName
  //   }
  // })

  const options = returnAllAssessmentSkills().map((skill) => {
    return {
      value: skill,
      label: skill
    }
  })

  const selectedOptions = skills.map((skill) => {
    return {
      value: skill,
      label: skill
    }
  })

  const customStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999, fontSize: 14 }),
    container: provided => ({...provided, fontSize: 14}),
    control: provided => ({ ...provided, borderColor: '#e5e7eb' })
    // indicatorContainer: provided => ({...provided, paddingTop: '2px', paddingBottom: '2px'}),
  }

  return (
    <CreatableSelect
      // defaultValue={[options[2], options[3]]}
      isMulti
      name="colors"
      options={options}
      className="basic-multi-select w-full z-50 text-base"
      classNamePrefix="select"
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      styles={customStyles}
      creatable
      onCreateOption={option => handleCreateSkill(option)}
      onChange={(options) => { handleChangeSkills(options)}}
      value={selectedOptions}
      placeholder="Select or create new..."
    />
  )
}

export default SkillSelect