// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: "AIzaSyCJBerLZO1MXRO7wTgit4cIcShOcr0GGkw",
  authDomain: "seller-rank.firebaseapp.com",
  projectId: "seller-rank",
  storageBucket: "seller-rank.appspot.com",
  messagingSenderId: "920077951419",
  appId: "1:920077951419:web:f6f23a9905a843e0eba49e",
  measurementId: "G-G71MH5SPCY"
};


// console.log('REACT_APP_CLOUD_ENV: ', process.env.REACT_APP_CLOUD_ENV);
// const firebaseConfig = process.env.REACT_APP_CLOUD_ENV === "PROD" ? prodFirebaseConfig : devxFirebaseConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
let db;
let auth;


if (process.env.REACT_APP_EMULATOR==="true") {
  
  console.log({emulator: process.env.REACT_APP_EMULATOR});

  db = getFirestore();
  connectFirestoreEmulator(db, 'localhost', 8080)

  auth = getAuth();
  connectAuthEmulator(auth, "http://localhost:9099");

  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, 'localhost', 5001);

} else {
  db = getFirestore(app);
  auth = getAuth(app);
}

/* Firestore emulator */
export { analytics, auth, db as default };
