import AttemptsTableRow from './AttemptsTableRow';
import {useEffect, useState} from 'react';
import { useStore } from '../store/store';
import { getAssessmentsList } from '../actions/assessments';
import { getAttempts } from '../actions/attempts';
import LoadingPage from "./LoadingPage";
import { pencilAltIcon, clipboardIcon, eyeIcon, largeDownChevron } from "../helpers/icons";
import { returnAssessmentPath, returnPublishedAssessmentUrl } from '../helpers/urls';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmptyAttemptsDashboardPage from "./EmptyAttemptsDashboardPage";
import EmptyAttempts from "./EmptyAttempts";
import { toast } from 'react-toastify';
// import CreateAssessmentModal from "./CreateAssessmentModal";
// import CreateAssessmentFromScratchModal from './CreateAssessmentFromScratchModal';
import SelectAssessmentModal from './SelectAssessmentModal';
import {pluralizeVerb} from '../helpers/pluralize';


const AttemptsDashboard = () => {

  const [createAssessmentFromScratchModalOpen, setCreateAssessmentFromScratchModalOpen] = useState(false);

  const user = useStore(state => state.user);
  const [assessmentsOptions, setAssessmentsOptions] = useState([]);
  const [assessmentsListLoading, setAssessmentsListLoading] = useState(false);
  const [attemptsListLoading, setAttemptsListLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [attemptsList, setAttemptsList] = useState([]);
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let openCreateModal = searchParams.get("openCreateModal");
  let selectedAssessmentId = searchParams.get("selectedAssessmentId");
  const [selectedAssessment, setSelectedAssessment] = useState(selectedAssessmentId ? selectedAssessmentId : null);
  const [selectAssessmentModalOpen, setSelectAssessmentModalOpen] = useState(false);
  const filteredAttemptsList = attemptsList.filter((attempt) => {
    return attempt?.assessmentId === selectedAssessment;
  })

  // console.log({ openCreateModal: openCreateModal === 'true' })
  const defaultOpen = openCreateModal === 'true'
  const [open, setOpen] = useState(defaultOpen);
  let published = assessmentsOptions.filter((option) => option.value === selectedAssessment)[0]?.published;
  // let numAttempts = assessmentsOptions.filter((option) => option.value === selectedAssessment)[0]?.numAttempts;
  // let numDecided = assessmentsOptions.filter((option) => option.value === selectedAssessment)[0]?.numDecided;
  let numAttempts = filteredAttemptsList.length;
  let numYes = filteredAttemptsList?.filter(option => option?.decision === 'yes')?.length;
  let numNo = filteredAttemptsList?.filter(option => option?.decision === 'no')?.length;
  let numDecided = numYes + numNo;

  let needReview = numAttempts - numDecided;  


  useEffect(() => {
    setAssessmentsListLoading(true);
    getAssessmentsList({ user }).then((assessmentsList) => {
      if (!assessmentsList || Object.values(assessmentsList).length === 0) {
        // console.log('No assessments viewable');
        setOpen(true);
        setAssessmentsListLoading(false);
      } else {
        setAssessmentsListLoading(false);
        let assessmentsOptions = [];
        Object.values(assessmentsList).forEach(({ jobId, jobTitle, assessmentId, assessment, published, numAttempts, numDecided }) => {
          assessmentsOptions.push({ label: jobTitle, value: assessmentId, jobId, published, numAttempts, numDecided });
        })
        setAssessmentsOptions(assessmentsOptions);
        if (!selectedAssessmentId) {
          setSelectedAssessment(assessmentsOptions[0].value);
        }
        
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error downloading assessmentslist', errorCode, errorMessage)
      setErrorMessage(errorMessage);
      // setOrgIsBeingCreated(false);
      setAssessmentsListLoading(false);
      // setLoading(false);
      // setFallback(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (assessmentsListLoading && (!assessmentsOptions?.length || assessmentsOptions?.length === 0) ) {
      setOpen(true);
    } else {
      setOpen(defaultOpen);
      setAttemptsListLoading(true);
      getAttempts({ user, completed: true }).then((attempts) => {

        if (!attempts || Object.values(attempts).length === 0) {
          // console.log('No attempts viewable');
          // setOpen(true);
          setAttemptsListLoading(false);
        } else {
          let attemptsList = [];
          Object.values(attempts).map(({ name, email, score, dateCreated, decision, attemptId, assessmentId, completed }) => {
            return attemptsList.push({ name, email, score, dateCreated, decision, attemptId, assessmentId, completed });
          });
          setAttemptsList(attemptsList);
          setAttemptsListLoading(false);
        }
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Error downloading attempts', errorCode, errorMessage)
        setErrorMessage(errorMessage);
        // setOrgIsBeingCreated(false);
        setAttemptsListLoading(false);
        // setLoading(false);
        // setFallback(true);
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreateAssessment = () => {
    setOpen(true);
  }

  const notify = (text) => toast(text);


  const handleEditAssessment = () => {
    navigate(returnAssessmentPath({ assessmentId: selectedAssessment }));
  }

  const publishedAssessmentUrl = returnPublishedAssessmentUrl({ assessmentId: selectedAssessment });
  const handleCopyAssessmentLink = () => {
    navigator.clipboard.writeText(publishedAssessmentUrl);
    notify('Copied assessment link');
  }

  const handleViewAsCandidate = () => {
    window.open(publishedAssessmentUrl, '_blank');
  }

  const handleSaveDecision = ({attemptId, decision}) => {
    let newAttemptsList = attemptsList
      .map((attempt) => {
        if (attempt?.attemptId === attemptId) {
          return { ...attempt, decision }
        } else {
          return attempt;
        }
      })
    setAttemptsList(newAttemptsList);
  }




  // console.log({ assessmentsListLoading , assessmentsOptions });
  if (assessmentsListLoading || attemptsListLoading) {
    return <LoadingPage />
  } else if (assessmentsOptions.length === 0) {
    return <EmptyAttemptsDashboardPage 
      handleCreateAssessment={handleCreateAssessment}
      open={open}
      setOpen={setOpen}
      createAssessmentFromScratchModalOpen={createAssessmentFromScratchModalOpen}
      setCreateAssessmentFromScratchModalOpen={setCreateAssessmentFromScratchModalOpen}
    />
  } else return (
    
    <div>
      <div className=" bg-gray-50 min-h-screen-adj">
        <header className="shadow">
          <div className="pt-6 pb-2 px-4 sm:px-6 lg:px-8 flex justify-between items-center w-full">
            <div className="">
              <span className="mx-2 font-light text-sm">Attempts for</span>
              <button 
                className="py-1 px-2 text-3xl font-semibold flex items-end hover:bg-gray-200  rounded"
                onClick={() => {setSelectAssessmentModalOpen(true)}}
              >
                {assessmentsOptions.filter(option => option.value === selectedAssessment)[0].label}
                <div className="ml-2">{largeDownChevron}</div>
              </button>
            </div>
            <div className="flex items-end justify-end text-xs w-72 space-x-2 flex-col">
              <button
                className="p-1 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
                onClick={() => { handleEditAssessment() }}
              >
                <span className="pr-1">{pencilAltIcon}</span>
                Edit assessment
              </button>
              {published ? '' : <span
                className="p-1 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
              >
                Assessment is not visible to candidates
              </span>}
              {!published ? '' : <button
                className="p-1 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
                onClick={() => { handleCopyAssessmentLink() }}
              >
                <span className="pr-1">{clipboardIcon}</span>
                Share with candidate
              </button>}
              {!published ? '' : <button
                className="p-1 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
                onClick={() => { handleViewAsCandidate() }}
              >
                <span className="pr-1">{eyeIcon}</span>
                View as candidate
              </button>}
            </div>
          </div>
        </header>
        <main>
          <div className="flex flex-col px-4 sm:px-6 lg:px-8 mt-4">

          {/* Table */}
            <div className="font-light text-sm">{numAttempts} attempts, {needReview} {pluralizeVerb('need', needReview)} review</div>
          {filteredAttemptsList.length === 0 ?
            <EmptyAttempts
              assessmentId={selectedAssessment}
            />
            : (
              <div className="my-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-200">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Candidate
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Score
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Decision
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Actions
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {
                          filteredAttemptsList
                            .sort((a, b) => b.dateCreated - a.dateCreated)
                            .map(({ name, email, score, dateCreated, decision, attemptId }, index) => {
                              return <AttemptsTableRow
                                key={index}
                                name={name}
                                email={email}
                                score={score}
                                dateCreated={dateCreated}
                                decision={decision}
                                attemptId={attemptId}
                                assessmentId={selectedAssessment}
                                handleSaveDecision={handleSaveDecision}
                              />
                            })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
      {errorMessage}
      <SelectAssessmentModal 
        open={selectAssessmentModalOpen} 
        setOpen={setSelectAssessmentModalOpen}
        selectedAssessment={selectedAssessment}
        setSelectedAssessment={setSelectedAssessment}
        assessmentsOptions={assessmentsOptions}
      />
    </div>
  )
}

export default AttemptsDashboard;