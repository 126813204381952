import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { exclamationIcon } from "../helpers/icons";
import { useStore } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { returnTemplateAssessmentPath } from '../helpers/urls';
import { getTemplateAssessmentsList } from '../actions/templateAssessments';
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

const override = css`
  border: none;
`;



const CreateAssessmentModal = ({ open, setOpen, setNextOpen }) => {

  const user = useStore(state => state.user);
  const [errorMessage, setErrorMessage] = useState('');
  const cancelButtonRef = useRef(null)
  const textAreaRef = useRef(null);
  let navigate = useNavigate();
  const [templateAssessmentsList, setTemplateAssessmentsList] = useState([]);
  const [templateAssessmentsListLoading, setTemplateAssessmentsListLoading] = useState(true);

  const handleStartFromScratch = () => {
    setOpen(false);
    setNextOpen(true);
  }

  useEffect(() => {
    // This is fetching data even before this modal is open.
    setTemplateAssessmentsListLoading(true);
    getTemplateAssessmentsList({user}).then((data) => {
      const {templateAssessmentsList} = data;
      setTemplateAssessmentsList(templateAssessmentsList);
      setTemplateAssessmentsListLoading(false);
      setErrorMessage('');
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      setTemplateAssessmentsListLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {templateAssessmentsListLoading ? (<div
              className="flex justify-center items-center"
            >
              <BeatLoader color='#3B82F6' css={override} loading={true} size={25} />
            </div>) :
            (
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        <div>Start with a pre-built assessment</div>
                        {/* <div className="text-xs text-gray-500">Then customize it to your role</div> */}
                      </Dialog.Title>
                      <div className="mt-4 w-96">
                        {templateAssessmentsList.map(({
                          templateAssessmentId,
                          jobTitle,
                          skills,
                          numQuestions,
                          duration
                        }) => (
                          <button
                            key={templateAssessmentId}
                            className="my-4 rounded-md border shadow hover:shadow-lg  p-2 w-full text-left bg-white"
                            onClick={() => { 
                              console.log(returnTemplateAssessmentPath({ templateAssessmentId }))
                              navigate(returnTemplateAssessmentPath({templateAssessmentId})) }}
                          >
                            <div className="font-semibold mb-2">{jobTitle}</div>
                            {skills.map((skill, index) => (
                              <div
                                key={index}
                                className="text-xs inline-block py-1 px-2.5 m-1 leading-none text-center font-semibold bg-blue-100 text-blue-600 rounded-full"
                              // key={skill}
                              >
                                {skill}
                              </div>
                            )
                            )}

                          </button>
                          )
                        )}

                        <button 
                          className={`mt-4 text-xs font-medium w-full text-center text-blue-500 hover:underline`}
                          onClick={() => {handleStartFromScratch()}}
                        >
                          Or start from scratch
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CreateAssessmentModal;