// export const domain = 'localhost:3000'
export const domain = window.location.origin;
export const landingDomain = `https://${domain.split('://app.')[1]}`;
export const homePath = '/';
export const signInPath = '/signin';
export const signPath = '/sign';
export const signUpPath = '/signup';
export const accountPath = '/account';
export const assessmentsPath = '/assessments';
export const attemptsPath = '/attempts';
export const termsUrl = `${landingDomain}/terms-of-service`;
export const privacyPolicyUrl = `${landingDomain}/privacy-policy`;
export const takeAsssessmentPath = '/takeassessment';
// export const apiUrl = process.env.REACT_APP_CLOUD_ENV === 'PROD' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEVX;
export const apiUrlEmulator = `http://localhost:5001/seller-rank/us-central1/api`;
export const apiUrl = process.env.REACT_APP_EMULATOR == 'true' ? apiUrlEmulator : process.env.REACT_APP_API_URL;
export const returnAssessmentPath = ({assessmentId}) => `/assessments/${assessmentId}`;
export const returnPublishedAssessmentUrl = ({assessmentId}) => `${domain}${takeAsssessmentPath}/${assessmentId}`;
export const returnInstructionsPath = ({ assessmentId }) => `${domain}${takeAsssessmentPath}/${assessmentId}/instructions`;
export const returnTemplateAssessmentPath = ({templateAssessmentId}) => `/templateassessments/${templateAssessmentId}`;
export const returnDashboardPath = ({openCreateModal}) => `/attempts?openCreateModal=${openCreateModal}`
export const returnViewAttemptsPath = ({assessmentId}) => `/attempts?selectedAssessmentId=${assessmentId}`
export const returnAttemptPath = ({ attemptId }) => `/attempts/${attemptId}`;
export const contactUrl = `${landingDomain}/contact`;
