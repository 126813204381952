import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { createAttempt, answerQuestion } from '../actions/attempts';
import { getInitialPublishedAssessment } from '../actions/publishedAssessments';
import LoadingPage from './LoadingPage';
import TakeAssessmentEnterName from './TakeAssessmentEnterName';
import TakeAssessmentInstructions from './TakeAssessmentInstructions';
import {validateEmail} from '../helpers/validation';
import TakingAssessment from './TakingAssessment';
import TakeAssessmentCompleted from './TakeAssessmentCompleted';
import FallbackPage from './FallbackPage';



const TakeAssessmentPage = () => {

  const [jobTitle, setJobTitle] = useState('');
  const [orgName, setOrgName] = useState('');
  const [numQuestions, setNumQuestions] = useState(0);
  const [duration, setDuration] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  let { assessmentId } = useParams();
  const minutes = duration / 60;
  const [screen, setScreen] = useState('instructions');
  const [attemptId, setAttemptId] = useState('');
  const [question, setQuestion] = useState();
  const [questionNumber, setQuestionNumber] = useState();
  const [selected, setSelected] = useState();
  const [fallback, setFallback] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState();





  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getInitialPublishedAssessment({ assessmentId }).then(({ jobTitle, orgName, numQuestions, duration }) => {
      if (!isMounted) return;
      setJobTitle(jobTitle);
      setOrgName(orgName);
      setNumQuestions(numQuestions);
      setDuration(duration);
      setLoading(false);
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setFallback(true);
      setLoading(false);
    })
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSignupForm = () => {
    let validated = false
    if (!name) {
      setErrorMessage('Please enter your full name.');
    } else if (!name.includes(' ')) {
      setErrorMessage('Please enter your full name.');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleSendName = () => {
    if (!validateSignupForm()) return;
    setButtonLoading(true);
    createAttempt({ name, email, assessmentId }).then(({attemptId, question, questionNumber}) => {
      setAttemptId(attemptId);
      setQuestion(question);
      const seconds = question?.seconds;
      setSecondsRemaining(seconds)
      setQuestionNumber(questionNumber);
      // navigate(returnInstructionsPath({assessmentId}));
      // amplitude.getInstance('client').logEvent('updateQuestion', { questionId, questionText });
      setErrorMessage('');
      setScreen('takingAssessment');
      setButtonLoading(false);
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }

  const handleAnswerQuestion = () => {
    if(buttonLoading) return;
    setButtonLoading(true);
    answerQuestion({ attemptId, questionNumber, questionId: question.questionId, selected }).then(({ attemptId, question, questionNumber, completed }) => {
      if (completed) {
        setScreen('completed');
      } else {
        setAttemptId(attemptId);
        setQuestion(question);
        setQuestionNumber(questionNumber);
        const seconds = question?.seconds;
        setSecondsRemaining(seconds)
      }
      // navigate(returnInstructionsPath({assessmentId}));
      // amplitude.getInstance('client').logEvent('updateQuestion', { questionId, questionText });
      setErrorMessage('');
      // setScreen('takingAssessment');
      setButtonLoading(false);
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }
  

  if (loading) {
    return <LoadingPage />
  } else if (fallback) {
      return <FallbackPage />
  } else if (screen === 'instructions') {
    return <TakeAssessmentInstructions 
      numQuestions={numQuestions}
      minutes={minutes}
      handleClick={() => {setScreen('enterName')}}
      errorMessage={errorMessage}
      buttonLoading={buttonLoading}
      jobTitle={jobTitle}
      orgName={orgName}
    />
  } else if (screen === 'enterName') {
    return <TakeAssessmentEnterName
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      handleClick={handleSendName}
      errorMessage={errorMessage}
      buttonLoading={buttonLoading}
      jobTitle={jobTitle}
      orgName={orgName}
    />
  } else if (screen === 'takingAssessment') {
    return <TakingAssessment 
      numQuestions={numQuestions}
      question={question}
      questionNumber={questionNumber}
      selected={selected}
      setSelected={setSelected}
      buttonLoading={buttonLoading}
      handleClick={handleAnswerQuestion}
      attempt={attemptId}
      secondsRemaining={secondsRemaining}
      setSecondsRemaining={setSecondsRemaining}
    />
  } else if (screen === 'completed') {
    return <TakeAssessmentCompleted 
      name={name}
      orgName ={orgName}
      errorMessage={errorMessage}
    />
  }
}

export default TakeAssessmentPage;