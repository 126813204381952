import { auth } from '../firebase/firebase';
import { signOut } from 'firebase/auth';
import { useStore } from '../store/store';
import { useRef, useEffect } from 'react'
import { homePath, signPath, accountPath, contactUrl /*, orgPath */ } from '../helpers/urls';
// import amplitude from 'amplitude-js';
import { useLocation, useNavigate } from 'react-router-dom';



const AccountMenu = ({
  // open,
  setOpen
}) => {

  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const setAccount = useStore(state => state.setAccount);
  const setEmail = useStore(state => state.setEmail);
  const menuRef = useRef(null);
  let navigate = useNavigate();
  const location = useLocation()
  // const onSigninPath = location.pathname.substring(0, takeAsssessmentPath?.length) === takeAsssessmentPath;


  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef])


  const handleLogout = () => {
    signOut(auth).then(() => {
      // amplitude.getInstance('client').logEvent('signOut', { uid: user.uid });
      navigate(homePath);
      setOpen(false);
      setUser(null);
      setAccount(null);
      setEmail('');
      console.log('Logged out user');
    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <div ref={menuRef} className="absolute right-0 top-8 text border bg-white text-black rounded flex flex-col shadow-lg">
      {!user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { handleLogout() }}>Log out</button>}
      {user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { navigate(signPath) }}>Sign in</button>}
      {!user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { navigate(accountPath) }}>Account</button>}
      {/* {!user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { navigate(orgPath) }}>Manage users</button>} */}
      {!user ? '' : <button className="hover:bg-gray-50 p-2 w-32 text-center" onClick={() => { window.open(contactUrl, '_blank') }}>Contact</button>}

    </div>
  )
}

export default AccountMenu;