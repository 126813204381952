import { plusIcon } from '../helpers/icons';
import CreateAssessmentModal from "./CreateAssessmentModal";
import CreateAssessmentFromScratchModal from './CreateAssessmentFromScratchModal';

const EmptyAssessmentsPage = ({handleCreateAssessment, open, setOpen, createAssessmentFromScratchModalOpen, setCreateAssessmentFromScratchModalOpen}) => {
  return (
    <div className="min-h-screen-adj flex items-center justify-center">
      <div className="text-center pb-56">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">Create a new assessment.</h3>
        {/* <p className="mt-1 text-sm text-gray-500">Get started by creating a new assessment.</p> */}
        <div className="mt-6">
          <button
            className="rounded-md text-sm bg-blue-500 text-white font-semibold p-2 my-2 px-2 hover:bg-blue-700 flex items-center"
            // className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"

            onClick={() => handleCreateAssessment()}
          >
            {plusIcon}
            <span className="ml-2">Create Assessment</span>
            </button>
        </div>
      </div>
      <CreateAssessmentModal open={open} setOpen={setOpen} setNextOpen={setCreateAssessmentFromScratchModalOpen} />
      <CreateAssessmentFromScratchModal open={createAssessmentFromScratchModalOpen} setOpen={setCreateAssessmentFromScratchModalOpen} />

    </div>
  )
}

export default EmptyAssessmentsPage;