import { apiUrl } from '../helpers/urls';

export const createAttempt = async ({ name, email, assessmentId }) => {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/attempts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, assessmentId })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const answerQuestion = async ({ attemptId, questionNumber, questionId, selected }) => {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/attempts/answerQuestion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ attemptId, questionNumber, questionId, selected })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getAttempts = async ({ user, completed }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/attempts?completed=${completed.toString()}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const saveDecision = async ({ user, attemptId, decision }) => {
  const idToken = !user ? null : await user.getIdToken();

  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/attempts/${attemptId}/updatedecision`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ decision })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getAttempt = async ({ user, attemptId}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/attempts/${attemptId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}