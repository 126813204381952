import {useState} from 'react';
import { thumbsUpIcon, thumbsDownIcon, darkThumbsUpIcon, darkThumbsDownIcon, mailIcon, largeEyeIcon, largeClipboardCheckIcon } from '../helpers/icons';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStore } from '../store/store';
import {saveDecision} from '../actions/attempts';
import { returnAttemptPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { returnScore } from '../helpers/sorting';


const ViewAttemptRow = ({
  score, 
  assessmentDecision, 
  attemptId, 
  assessmentId,
  questions,
  numQuestions
}) => {

  const [decision, setDecision] = useState(assessmentDecision);
  const [decisionLoading, setDecisionLoading] = useState(false);
  const user = useStore(state => state.user);
  const notify = (text) => toast(text);
  let navigate = useNavigate();

  const handleToggleDecision = (newChoice) => {
    let newDecision = decision === newChoice ? '' : newChoice;
    setDecisionLoading(true);
    saveDecision({user, attemptId, decision: newDecision}).then(() => {
      setDecision(newDecision);
      setDecisionLoading(false);
    }).catch((error) => {
      console.error(error);
      setDecisionLoading(false);
    })
  }


  const handleViewAttempt = () => {
    navigate(returnAttemptPath({attemptId}))
  }

  const numCorrect = questions.filter(question => question.correctlyAnswered).length;
  // const score = returnScore({ numQuestions, numCorrect });
  let skillsMap = {};
  questions.forEach((question) => {
    question?.skills?.forEach((skill) => {
      let result = skillsMap[skill] ? [...skillsMap[skill]] : [];
      skillsMap[skill] = [...result, question?.questionId];
    })
  });
  let skillsQuestionMap = {};
  Object.entries(skillsMap).forEach((entry) => {
    let skill = entry[0];
    let numQuestions = entry[1].length;
    skillsQuestionMap[skill] = numQuestions;
  });

  let newSkillsMap = {};
  questions.forEach((question) => {
    question?.skills?.forEach((skill) => {
      let result = newSkillsMap[skill] ? [...newSkillsMap[skill]] : [];
      if (question?.correctlyAnswered) {
        result = [...result, question?.questionId]
      }
      newSkillsMap[skill] = [...result]
    })
  });
  let skillsCorrectMap = {};
  Object.entries(newSkillsMap).forEach((entry) => {
    let skill = entry[0];
    let numQuestions = entry[1].length;
    skillsCorrectMap[skill] = numQuestions;
  });



  return (
    <tr>
      <td className="py-4 whitespace-nowrap">
        <div className="">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{score}% {`(${numCorrect} / ${numQuestions})`}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-start">
          <div className="ml-4">
            {Object.keys(skillsMap).map((skill) => {

              const skillScore = returnScore({ numQuestions: skillsQuestionMap[skill], numCorrect: skillsCorrectMap[skill] })

              return (
                <div className="flex justify-between">
                  <div className="text-sm font-medium text-gray-900 mr-8">{skill}</div>
                  <div className="text-sm font-medium text-gray-900">{skillScore}% {`(${skillsCorrectMap[skill]} / ${skillsQuestionMap[skill]})`}</div>
                </div>
              )
            })}
          </div>
        </div>
      </td>

      <td className="py-4 whitespace-nowrap">
        <div className="w-full">
          <div className="ml-4 w-full">
            <div className="text-sm font-medium text-gray-900 w-full text-left">
              {decision !== 'yes' ? '' : <span className="text-blue-500 font-semibold">Yes!</span>}
              {decision !== 'no' ? '' : <span className="text-red-500 font-semibold">No</span>}
            </div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="ml-4">
          <button 
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleToggleDecision('yes') }}
            disabled={decisionLoading}
          >
              {decision === 'yes' ? darkThumbsUpIcon : thumbsUpIcon}
          </button>
          <button
            className="p-2 hover:bg-gray-100 rounded-full"
            onClick={() => { handleToggleDecision('no') }}
            disabled={decisionLoading}
          >
            {decision === 'no' ? darkThumbsDownIcon : thumbsDownIcon}
          </button>

        </div>
      </td>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
    </tr>
  )
}

export default ViewAttemptRow;