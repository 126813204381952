import ProgressBar from "./ProgressBar";
import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";
import { useEffect, useState } from "react";
import LoadingPage from "./LoadingPage";


const TakingAssessment = ({ 
  errorMessage, 
  buttonLoading, 
  numQuestions, 
  question, 
  questionNumber, 
  selected, 
  setSelected, 
  handleClick, 
  attemptId,
  secondsRemaining,
  setSecondsRemaining
}) => {

  
  const {choices, queryText, questionId} = question;

  useEffect(() => {
    if (secondsRemaining <= 0) {
      console.log('Time has run out.');
      const { questionId } = question;
      handleClick({ attemptId, questionNumber, questionId, selected })
    } else {
      const timer = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1)
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsRemaining]);
  
  return buttonLoading ? <LoadingPage /> : (
    <div className="min-h-screen-adj flex justify-center py-24 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-md w-full space-y-8">
        <div className="p-10">
          <div className="text-gray-500 text-xs text-center mb-2 flex justify-between">
            <div>Question {questionNumber} of {numQuestions}</div>
            <div>{secondsRemaining} seconds remaining</div>
          </div>
          <ProgressBar totalSteps={numQuestions} currentStep={questionNumber} />
        </div>

        <div>
          <div className="mt-6 py-4 text-gray-900 text-lg display-linebreak">
            {queryText}
          </div>
        </div>
        <div className="space-y-4">

          {/* Answer choices */}
          <div>
            {choices.map(({choiceId, choiceText}) => {
              return <button 
                key={choiceId} 
                className={`w-full rounded-lg px-4 py-2 my-2 text-left border   ${selected !== choiceId ? `hover:bg-gray-300 hover:border-gray-300 bg-gray-200 border-gray-300` : `hover:bg-blue-200 hover:border-blue-600 border-blue-300 bg-blue-200 text-blue-600`} `}
                onClick={() => {selected === choiceId ? setSelected(null) : setSelected(choiceId)}}
              >
                {choiceText}
              </button>
            })}
          </div>

          {/* Answer choices */}
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-200"
              disabled={!selected}
              onClick={() => { handleClick({ attemptId, questionNumber, questionId, selected }) }}
            // disabled={orgIsBeingCreated}
            >
              {buttonLoading ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {buttonLoading ? '' : 'Next'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div>
    </div>
  )
}

export default TakingAssessment;