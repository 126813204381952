// import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { useStore } from '../store/store';
import { signPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LoadingPage from '../components/LoadingPage';

const NavRoute = ({ authRequired, children}) => {

  let navigate = useNavigate();
  const user = useStore(state => state.user);

  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    if (authRequired && !user?.uid) {
      navigate(signPath);
      window.location.reload()
    }
    setAuthLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])


  return authLoading ? <LoadingPage /> : (
    <div>
      <Navbar />
      <div className="min-h-screen-adj">
        {children}
      </div>
    </div>

  )
}
export default NavRoute;
