import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";
import { useState } from 'react';
import {validateEmail} from '../helpers/validation';
import { useNavigate } from 'react-router-dom';
import {useStore} from '../store/store';
import {findUserByEmail} from '../actions/accounts';
import {signUpPath, signInPath} from '../helpers/urls';



const SignInOrUp = () => {

  let navigate = useNavigate();
  // const [name, setName] = useState('');
  // const [orgName, setOrgName] = useState('');
  // const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [terms, setTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const setUser = useStore(state => state.setUser);
  // const setAccount = useStore(state => state.setAccount);
  // const [fallback, setFallback] = useState(false);
  const [checkingAccount, setCheckingAccount] = useState(false);
  const storeEmail = useStore(state => state.setEmail);


  const validateSignupForm = () => {
    let validated = false
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = () => {

    if (!validateSignupForm()) return;

    setCheckingAccount(true);

    findUserByEmail({email}).then( ({userFound}) => {
      setCheckingAccount(false);
      setErrorMessage('');
      storeEmail(email);
      if (userFound) {
        console.log(`User found: ${userFound}`);
        navigate(signInPath)
      } else {
        console.log(`User not found.`);
        navigate(signUpPath)
      }
    }).catch((error) => {
      setErrorMessage(errorMessage);
      console.log('Error: Cannot execute findUserByEmail.', error)
      setCheckingAccount(false);
    })
  }
      
  return (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            or enter your email to create an account.
          </p>
        </div>
        <div className="mt-8 space-y-4">

          {/* Email */}
          <div>
            <label htmlFor="email" className="text-sm font-semibold">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="bruce@wayneindustries.com"
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
            />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => { handleClick() }}
              disabled={checkingAccount}
            >
              {checkingAccount ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {checkingAccount ? '' : 'Next'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div>
    </div>
  )
}

export default SignInOrUp;