import {useState, useEffect, useCallback, useRef} from 'react';
import { useStore } from '../store/store';
import { getAssessment, publishAssessment, deleteQuestion, unpublishAssessment, updateAssessment } from '../actions/assessments';
import {useParams} from 'react-router-dom';
import {spinnerIcon, clipboardIcon, exclamationIcon, smallPlusIcon} from '../helpers/icons';
import PublishedModal from './PublishedModal';
import { returnPublishedAssessmentUrl } from '../helpers/urls';
import { ToastContainer, toast } from 'react-toastify';
import LoadingPage from './LoadingPage';
import AddQuestionModal from './AddQuestionModal';
import QuestionList from './QuestionList';
import Question from './Question';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import {sortQuestions} from '../helpers/sorting';
import _ from 'lodash';
import {saveTemplateAssessment} from '../actions/templateAssessments';

const EditAssessmentPage = (props) => {

  const [jobTitle, setJobTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const user = useStore(state => state.user);
  const [publishing, setPublishing] = useState(false);
  let {assessmentId} = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [publishedModalOpen, setPublishedModalOpen] = useState(false);
  const [published, setPublished] = useState(false);
  const notify = () => toast("Copied link");
  const publishedAssessmentUrl = returnPublishedAssessmentUrl({assessmentId});
  const [pageLoading, setPageLoading] = useState(false);
  const [loadAssessmentPage, setLoadAssessmentPage] = useState(0)
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
  const [deleteQuestionLoading, setDeleteQuestionLoading] = useState(false);
  const [questionOrder, setQuestionOrder] = useState();
  const [orderLoading, setOrderLoading] = useState(false);
  const draggingQuestion = useStore(state => state.draggingQuestion);
  const account = useStore(state => state.account);
  const setAccount = useStore(state => state.setAccount);
  const { publishedFirstAssessment = false } = account;
  const [unpublishing, setUnpublishing] = useState(false);
  const [publishAsTemplateLoading, setPublishAsTemplateLoading] = useState(false);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [editingJobTitle, setEditingJobTitle] = useState(false);
  const [tempJobTitle, setTempJobTitle] = useState('')
  const [priorJobTitle, setPriorJobTitle] = useState('');
  const [latestIsPublished, setLatestIsPublished] = useState(false);

  useEffect(() => {
    user.getIdTokenResult().then((idTokenResult) => {
      let superAdmin = !!idTokenResult?.claims?.superAdmin;
      setSuperAdmin(superAdmin);
      if (superAdmin) console.log({superAdmin})
    }).catch((error) => {
      console.error(error);
      console.log('Could not get claims');
    })
  }, [user]);

  const copyLink = () => {
    navigator.clipboard.writeText(publishedAssessmentUrl);
    notify();
  }

  const numQuestions = questions.length;
  const returnDuration = (questions) => {
    let duration = 0;
    questions.map((question) => question.seconds).forEach((seconds) => {
      duration = duration + seconds;
    });
    return duration;
  };
  const duration = returnDuration(questions);
  const minutes = duration / 60;

  useEffect(() => {
    if (loadAssessmentPage === null || loadAssessmentPage === undefined) return;
    setPageLoading(true);
    getAssessment({user, assessmentId}).then(({jobTitle, questions, published, questionOrder, latestIsPublished = false}) => {
      setJobTitle(jobTitle);
      let sortedQuestions = sortQuestions({questions, questionOrder});
      setQuestions(sortedQuestions);
      setPublished(published);
      setQuestionOrder(questionOrder ? questionOrder : Object.values(questions).map(({questionId}) => questionId))
      setPageLoading(false);
      setLatestIsPublished(latestIsPublished);
    }).catch((error) => {
      setPageLoading(false);
      console.error(error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAssessmentPage])

  const returnAllAssessmentSkills = () => {
    let allAssessmentSkills = [];
    Object.values(questions).forEach((question) => {
      let skills = question?.skills ? question?.skills : [];
      allAssessmentSkills = [...allAssessmentSkills, ...skills];
    });
    let allAssessmentSkillsMap = {}
    let withouDuplicates = [];
    allAssessmentSkills.forEach((skill) => {
      if (!(skill in allAssessmentSkillsMap)) {
        allAssessmentSkillsMap[skill] = skill;
        withouDuplicates.push(skill);
      }
    })
    return withouDuplicates;
  }

  const handlePublish = () => {
    setPublishing(true);
    publishAssessment({ user, assessmentId }).then((data) => {
      setPublishing(false);
      setPublishedModalOpen(true);
      setAccount({...account, publishedFirstAssessment: true});
      setPublished(true);
      // amplitude.getInstance('client').logEvent('updateQuestion', { questionId, questionText });
      setErrorMessage('');
      setLatestIsPublished(true);
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setPublishing(false);
    })
  }

  const handleUnpublish = () => {
    setUnpublishing(true);
    unpublishAssessment({ user, assessmentId }).then((data) => {
      setUnpublishing(false);
      setPublished(false);
      toast("Assessment was unpublished.");
      // amplitude.getInstance('client').logEvent('updateQuestion', { questionId, questionText });
      setErrorMessage('');
      setLatestIsPublished(false);
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setPublishing(false);
    })
  }

  const handleDeleteQuestion = ({assessmentId, questionId}) => {
    setDeleteQuestionLoading(true);
    deleteQuestion({user, assessmentId, questionId}).then(() => {
      setDeleteQuestionLoading(false);
      setLoadAssessmentPage(state => state + 1);
      setLatestIsPublished(false);
    }).catch((error) => {
      setDeleteQuestionLoading(false);
      console.error(error);
    })
  }

  const moveQuestionListItem = useCallback(
    async (dragIndex, hoverIndex) => {
      // console.log({dragIndex, hoverIndex});
      const dragItem = questions[dragIndex]
      const hoverItem = questions[hoverIndex]
      // Swap places of dragItem and hoverItem in the questions array
      const returnNewQuestions = (questions) => {
        const updatedQuestions = [...questions]
        updatedQuestions[dragIndex] = hoverItem
        updatedQuestions[hoverIndex] = dragItem
        return updatedQuestions
      };
      // let oldQuestions = questions;
      const newQuestions = returnNewQuestions(questions);
      const newQuestionOrder = newQuestions.map(({questionId}) => questionId);
      if (dragIndex === hoverIndex) return;
      setQuestions(newQuestions);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [questions]
  )

  useEffect( () => {
    const updateAssessmentWhenQuestionOrderChanges = async () => {
      try {
        const newQuestionOrder = questions.map(({ questionId }) => questionId);
        if (!!questionOrder && !_.isEqual(questionOrder, newQuestionOrder) && !draggingQuestion) {
          // setShowSave(true);
          await handleUpdateAssessment();
        }
      } catch (error) {
        setLoadAssessmentPage(true);
      }
    }
    updateAssessmentWhenQuestionOrderChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingQuestion])

  const handleUpdateAssessment = async () => {
    const newQuestionOrder = questions.map(({ questionId }) => questionId);
    let assessmentOrderIsChanged = !!questionOrder && !_.isEqual(questionOrder, newQuestionOrder)
    let jobTitleIsChanged = priorJobTitle !== jobTitle;
    if (assessmentOrderIsChanged || jobTitleIsChanged) {
      setOrderLoading(true);
      await updateAssessment({ user, assessmentId, questionOrder: newQuestionOrder, jobTitle }).then(() => {
        setOrderLoading(false);
        setJobTitle(jobTitle);
        setPriorJobTitle('');
        setQuestionOrder(newQuestionOrder);
        setLatestIsPublished(false);
        // setShowSave(false);
      }).catch((error) => {
        setJobTitle(priorJobTitle);
        setPriorJobTitle('');
        setOrderLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        setErrorMessage(errorMessage);
      });
    }
  }

  const handlePublishAsTemplate = () => {
    setPublishAsTemplateLoading(true);
    saveTemplateAssessment({user, assessmentId}).then(() => {
      setPublishAsTemplateLoading(false);
      console.log(`Published as template`);
    }).catch((error) => {
      setPublishAsTemplateLoading(false);
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
    })
  }

  const handleClickJobTitle = () => {
    setTempJobTitle(jobTitle);
    setEditingJobTitle(state => !state);
  }


  const jobTitleInputRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (jobTitleInputRef.current && !jobTitleInputRef.current.contains(event.target)) {
  //       setEditingJobTitle(false);
  //       setPriorJobTitle(jobTitle);
  //       setJobTitle(tempJobTitle);
  //       setTempJobTitle('');
  //     }
  //   }
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [jobTitleInputRef])

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setEditingJobTitle(false);
      setPriorJobTitle(jobTitle);
      setJobTitle(tempJobTitle);
      setTempJobTitle('');
    }
  }

  useEffect(() => {
    if (!!priorJobTitle) {
      handleUpdateAssessment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTitle])

  return pageLoading ? <LoadingPage /> : (
    <DndProvider backend={HTML5Backend}>

      <div className="min-h-screen-adj pb-14 bg-gray-50">
        <div className="">
          <header className="shadow sticky top-0 bg-gray-50">
            <div className="pt-6 pb-2 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <div>
                {!editingJobTitle ? <button 
                  className="text-3xl font-bold text-gray-900 text-left"
                  onClick={() => {handleClickJobTitle()}}
                  disabled={orderLoading}
                >{jobTitle}</button> : 

                <span className="flex">
                  <input
                    id="jobTitle"
                    name="jobTitle"
                    type="text"
                    required
                    className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 text-3xl font-bold"
                    value={tempJobTitle}
                    onChange={(e) => { setTempJobTitle(e.target.value) }}
                    onKeyPress={(e) => { handleKeyPress(e)}}
                    ref={jobTitleInputRef}
                  />
                </span>
                }
                <div className="text-xs text-gray-500">{numQuestions} questions, {Math.round(minutes)} minutes</div>
              </div>
              <div>
                <div className="flex justify-end">
                  {superAdmin && published ?  <button
                    className="mr-2 rounded-lg border border-gray-500 bg-white text-gray-500 py-1 px-2 text-sm hover:bg-gray-200 hover:text-gray-600 flex justify-center"
                    disabled={publishAsTemplateLoading}
                    onClick={() => { handlePublishAsTemplate() }}
                  >
                      <span className={publishAsTemplateLoading ? "invisible" : ''}>Publish as template</span>
                      {publishAsTemplateLoading ? <span className="absolute m-auto text-center bg-gray-100">{spinnerIcon}</span> : ""}
                  </button>: ''}
                  {!published ? '' : <button
                    className={`mr-2 rounded-lg border border-gray-500 bg-white text-gray-500 py-1 px-2 text-sm hover:bg-gray-200 hover:text-gray-600 flex justify-center`}
                    disabled={unpublishing}
                    onClick={() => { handleUnpublish() }}
                  >
                    <span className={publishing ? "invisible" : ''}>Unpublish</span>
                    {publishing ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                  </button>}
                  {/* {showSave ? '' : <button */}
                  {latestIsPublished ? '' : <button
                      className={`${!publishedFirstAssessment && numQuestions > 1 ? 'animate-bounce' : ''} rounded-lg border border-blue-500 bg-blue-100 text-blue-500 py-1 px-2 text-sm hover:bg-blue-200 hover:text-blue-600 flex justify-center`}
                    disabled={publishing}
                    onClick={() => { handlePublish() }}
                  >
                    <span className={publishing ? "invisible" : ''}>{published ? 'Publish changes' : 'Publish'}</span>
                    {publishing ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                  </button>}

                </div>
                {published ? <button 
                  className="text-xs text-gray-500 flex items-center mt-1 text-right"
                  onClick={() => {copyLink()}}
                >
                  {publishedAssessmentUrl}
                  <span className="ml-1">{clipboardIcon}</span>
                </button> : 
                <span
                  className="text-xs text-gray-500 flex items-center mt-1"
                >
                Assessment is not visible to candidates.
              </span>
                }
                
              </div>
            </div>
          </header>
          <main>
                    <QuestionList>
                    {questions.map((question, index) => {
                      const { questionId, queryText, skills, seconds, choices, choicesCollapsed } = question;
                        return (
                          <Question
                            key={questionId}
                            questionId={questionId}
                            queryText={queryText}
                            skills={skills}
                            seconds={seconds}
                            choices={choices}
                            choicesCollapsed={choicesCollapsed}
                            assessmentId={assessmentId}
                            setLoadAssessmentPage={setLoadAssessmentPage}
                            handleDeleteQuestion={handleDeleteQuestion}
                            moveListItem={moveQuestionListItem}
                            index={index}
                            returnAllAssessmentSkills={returnAllAssessmentSkills}
                            deleteQuestionLoading={deleteQuestionLoading}
                          />
                      )}
                    )}
                    </QuestionList>

              <div className="sm:px-6 lg:px-8">
                <button
                  className="mx-8 rounded-lg border border-gray-500 text-gray-700 py-1 bg-white px-2 text-sm hover:bg-gray-200 hover:text-gray-900 relative flex justify-center items-center font-bold"
                  onClick={() => { setAddQuestionModalOpen(true) }}
                >
                  <div className={`mr-1`}>{smallPlusIcon}</div>  
                  <span>Add question</span>
                </button>
            </div>
          </main>
        </div>
      <PublishedModal open={publishedModalOpen} setOpen={setPublishedModalOpen} assessmentId={assessmentId} />
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
      <AddQuestionModal 
        open={addQuestionModalOpen}
        setOpen={setAddQuestionModalOpen}
        assessmentId={assessmentId}
        setLoadAssessmentPage={setLoadAssessmentPage}
        returnAllAssessmentSkills={returnAllAssessmentSkills}
      />
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
    </DndProvider>

  )
}

export default EditAssessmentPage;