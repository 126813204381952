import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";
import { useState, useEffect } from 'react';
import {validateEmail} from '../helpers/validation';
import FallbackPage from './FallbackPage';
import { useNavigate } from 'react-router-dom';
import { /* homePath, */ signPath, termsUrl, privacyPolicyUrl } from '../helpers/urls';
import { createUserWithEmailAndPassword } from "firebase/auth";
import {useStore} from '../store/store';
import { auth } from '../firebase/firebase';
// import {createOrg} from '../actions/orgs';



const SignUp = () => {

  let navigate = useNavigate();


  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const setUser = useStore(state => state.setUser);
  // const setAccount = useStore(state => state.setAccount);
  const email = useStore(state => state.email);
  const [fallback, setFallback] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const validateSignupForm = () => {
    let validated = false
    if (!password) {
      setErrorMessage('Please enter your a password.');
    } else if (password.length < 8) {
      setErrorMessage('Your password must be at least 8 characters');
    } else if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
    } else if (!terms) {
      setErrorMessage('In order to sign up please check the box to agree to the Terms of Use and Privacy Policy.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = async () => {

    if (!validateSignupForm()) return;
    setCreatingAccount(true);

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setUser(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error creating user', errorCode, errorMessage)
        setErrorMessage(errorMessage);
        setCreatingAccount(false);
        setFallback(true);
      });
  }

  useEffect(() => {
    return setCreatingAccount(false);
  }, []);


  return fallback ? <FallbackPage message={`We're sorry.  There was a problem creating your account.`} /> : (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {`Or  `}
            <span onClick={() => { navigate(signPath) }} className="cursor-pointer font-medium text-blue-600 hover:text-blue-500">
              sign in
            </span> to an existing account.
          </p>
        </div>
        <div className="mt-8 space-y-4">

          {/* Email */}
          <div>
            <label htmlFor="email" className="text-sm font-semibold">Email</label>
            <input
              id="email"
              name="email"
              type="text"
              autoComplete="email"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="bruce@wayneindustries.com"
              disabled={!!email}
              value={email}
              onChange={(e) => { console.log(`Can't change field.`) }}
            />
          </div>

          {/* Password */}
          <div>
            <label htmlFor="password" className="text-sm font-semibold">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="password"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="••••••••"
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </div>

          {/* Terms */}
          <div className="flex items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              value={terms}
              onChange={() => { setTerms(state => !state) }}
            />
            <label
              htmlFor="terms"
              className="text-sm font-semibold ml-2 block"
            >
              I agree to the <a className="text-blue-500 hover:underline" href={termsUrl} rel="noreferrer" target="_blank">Terms of Use</a> and <a className="text-blue-500 hover:underline" href={privacyPolicyUrl} rel="noreferrer" target="_blank">Privacy Policy</a>.
            </label>
          </div>


          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => { handleClick() }}
              disabled={creatingAccount}
            >
              {creatingAccount ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {creatingAccount ? '' : 'Create your account'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div>
    </div>
  )
}

export default SignUp;