import {trashIcon, checkIcon} from '../helpers/icons';

const EditChoice = ({ correct, choiceText, choiceId, handleChangeChoiceText, handleChangeChecked, handleRemoveChoice, choiceIndex }) => {
  
  // const [checked, setChecked] = useState(false);
  
  return (
    <div id={choiceId}>
      <div
        className="flex items-center my-2"
      >
        {/* <input 
          type="checkbox" 
          checked={correct} 
          onChange={() => {handleChangeChecked({choiceIndex})}}
        /> */}
        <button
          className={`rounded-full ${correct ? 'bg-blue-500' : 'bg-blue-500'} text-white p-1`}
          checked={correct}
          onClick={() => { handleChangeChecked({ choiceIndex }) }}
        >
          <span className={correct ? '' : 'invisible'}>{checkIcon}</span>
        </button>
        <textarea
          className="border rounded resize-none w-full ml-2 p-1 sm:text-sm"
          type="text"
          rows="1"
          value={choiceText}
          onChange={(e) => handleChangeChoiceText({choiceIndex, newChoiceText: e.target.value})}
        />
          <button
            className="ml-1 p-1 hover:bg-gray-100 rounded-full"
            onClick={() => { handleRemoveChoice({choiceIndex}) }}
          >
            {trashIcon}
          </button>
      </div>
    </div>
  )
}

export default EditChoice;