import { Fragment } from 'react';
import Select from 'react-select'

const SelectJob = ({ selectedAssessment, handleSelectAssessment, assessmentsOptions}) => {
  
  let options = [...assessmentsOptions]
  const returnSelectedOption = (selectedAssessment, options) => {
    return options.filter((option) => {
      return option.value === selectedAssessment;
    })[0];
  }
  // let value = options.filter((option) => option.value = selectedAssessment)[0]
  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={assessmentsOptions[0].value}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        name="job"
        options={assessmentsOptions}
        // value={value}
        value={returnSelectedOption(selectedAssessment, options)}
        onChange={option => handleSelectAssessment({assessmentId: option.value})}

      />
    </Fragment>
  )
}

export default SelectJob;