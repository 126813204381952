import moment from 'moment';
import pluralize from 'pluralize';
import { clipboardIcon, eyeIcon, /* clipboardCheckIcon, pencilAltIcon */} from '../helpers/icons';
import { returnPublishedAssessmentUrl, returnAssessmentPath, returnViewAttemptsPath } from '../helpers/urls';
import { pluralizeVerb } from '../helpers/pluralize';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import Tooltip from './Tooltip';

const AssessmentsTableRow = ({
  jobTitle,
  dateUpdated,
  published,
  // createdBy,
  numAttempts,
  numDecided,
  numYes,
  numNo,
  assessmentId,
}) => {

  const notify = (text) => toast(text);
  let navigate = useNavigate();

  const handleEditAssessment = () => {
    navigate(returnAssessmentPath({ assessmentId }));
  }

  const publishedAssessmentUrl = returnPublishedAssessmentUrl({ assessmentId });
  const handleCopyAssessmentLink = () => {
    navigator.clipboard.writeText(publishedAssessmentUrl);
    notify('Copied assessment link');
  }

  const handleViewAsCandidate = () => {
    window.open(publishedAssessmentUrl, '_blank');
  }

  const handleViewAttempts = () => {
    navigate(returnViewAttemptsPath({ assessmentId }))
  }


  return (
    <tr id={assessmentId}>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            {/* <Tooltip content="Edit assessment"> */}
              <button 
                className="text-sm font-medium text-blue-500 hover:underline"
                onClick={() => {handleEditAssessment()}}
              >
                {jobTitle}
              </button>
            {/* </Tooltip> */}
            {/* <div className="text-sm text-gray-500">{email}</div> */}
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{moment(dateUpdated).format("MMM Do 'YY")}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{published ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap">
        <div className="flex items-center w-full">
          <div className="ml-4 w-full">
            <div className="text-sm font-medium text-gray-900 w-full text-left">
              {/* <Tooltip content="View attempts"> */}
                <button 
                  className="font-medium text-blue-500 hover:underline"
                  onClick={() => { handleViewAttempts() }}
                >{numAttempts} {pluralize('attempts', numAttempts)}</button>
              {/* </Tooltip> */}
              {/* <span className="py-1.5 font-medium text-gray-900">{numYes} Yes</span> 
              <span className="py-1.5 font-medium text-gray-900">{numNo} No</span>  */}
              <div className="text-gray-500 font-light">{numAttempts - numDecided} {pluralizeVerb('need', numAttempts - numDecided)} review</div>
            </div>
          </div>
        </div>
      </td>
      <td className="py-1.5 whitespace-nowrap">
        <div className="ml-4 flex flex-col items-start text-xs">
          {/* <button
            className="p-1.5 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
            onClick={() => { handleViewAttempts() }}
          >
            <span className="pr-1">{clipboardCheckIcon}</span>
            View attempts
          </button> */}
          {/* <button
            className="p-1.5 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
            onClick={() => { handleEditAssessment() }}
          >
            <span className="pr-1">{pencilAltIcon}</span>
            Edit assessment
          </button> */}
          {!published ? '' : <button
            className="p-1.5 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
            onClick={() => { handleCopyAssessmentLink() }}
          >
            <span className="pr-1">{clipboardIcon}</span>
            Share with candidate
          </button>}
          {!published ? '' : <button
            className="p-1.5 hover:bg-gray-100 rounded-lg flex items-center text-gray-700"
            onClick={() => { handleViewAsCandidate() }}
          >
            <span className="pr-1">{eyeIcon}</span>
            View as candidate
          </button>}
        </div>
      </td>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
    </tr>
  )
}

export default AssessmentsTableRow;