import { exclamationIcon } from "../helpers/icons";

const TakeAssessmentCompleted = ({ name, errorMessage, orgName }) => {
  return (
    <div className="flex justify-center items-center pt-24">

      <div className="text-center text-gray-800 py-10 px-6">
        <div className="text-3xl font-bold mt-0">Congratulations {name}!</div>
        <div className="text-gray-500 my-4">You completed this assessment.</div>
        <div className="text-xl font-bold my-4">{orgName}</div>
        <div className="text-gray-500 my-4">will be in touch with you soon.</div>

      </div>

      <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="mt-8 space-y-4">
          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}

        </div>
      </div>
    </div>
    </div>
  )
}

export default TakeAssessmentCompleted;