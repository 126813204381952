import {useState, useEffect} from 'react';
import { useStore } from '../store/store';
import {useParams} from 'react-router-dom';
import {exclamationIcon} from '../helpers/icons';
import { ToastContainer } from 'react-toastify';
import LoadingPage from './LoadingPage';
import QuestionList from './QuestionList';
import QuestionFromAttempt from './QuestionFromAttempt';
import {sortQuestions, returnScore} from '../helpers/sorting';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { returnViewAttemptsPath } from '../helpers/urls';
import {getAttempt} from '../actions/attempts';
import moment from 'moment';
import ViewAttemptRow from './ViewAttemptRow';

const ViewAttemptPage = (props) => {

  const [assessmentId, setAssessmentId] = useState('');
  const [completed, setCompleted] = useState();
  const [dateCreated, setDateCreated] = useState('');
  const [decision, setDecision] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [jobTitle, setJobTitle] = useState('');
  const user = useStore(state => state.user);
  let {attemptId} = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [loadAssessmentPage, setLoadAssessmentPage] = useState(0)
  let navigate = useNavigate();


  const numQuestions = questions.length;
  const returnDuration = (questions) => {
    let duration = 0;
    questions.map((question) => question.seconds).forEach((seconds) => {
      duration = duration + seconds;
    });
    return duration;
  };
  const duration = returnDuration(questions);
  const minutes = duration / 60;

  useEffect(() => {
    if (loadAssessmentPage === null || loadAssessmentPage === undefined) return;
    setPageLoading(true);
    getAttempt({user, attemptId}).then(({
      assessmentId, 
      completed, 
      dateCreated, 
      decision,
      email,
      name,
      questionOrder,
      questions,
      jobTitle
    }) => {
      setAssessmentId(assessmentId);
      setCompleted(completed);
      setDateCreated(dateCreated);
      setDecision(decision);
      setEmail(email);
      setName(name);
      setJobTitle(jobTitle);
      setPageLoading(false);
      // setJobTitle(jobTitle);
      questions = Object.values(questions);
      let sortedQuestions = sortQuestions({questions, questionOrder});
      setQuestions(sortedQuestions);
      // setPageLoading(false);
    }).catch((error) => {
      setPageLoading(false);
      console.error(error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAssessmentPage]);

  const numCorrect = questions.filter(question => question.correctlyAnswered).length;
  const score = returnScore({ numQuestions, numCorrect });

  return pageLoading ? <LoadingPage /> : (

    <div >
      <div className=" bg-gray-50 min-h-screen-adj pb-14">
        <header className="shadow">
          <div className="py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-end">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{name}</h1>
              <div className="text-xs text-gray-500">{email}</div>
            </div>
            <div className='text-end'>

                <div className="text-xs text-gray-500">Attempted {moment(dateCreated).format("MMM Do 'YY")}</div>
                <div className="text-xs text-gray-500">{numQuestions} questions, {Math.round(minutes)} minutes</div>
                <button
                  className={`text-xs text-blue-500 hover:underline font-medium p-0 m-0`}
                  onClick={() => { navigate(returnViewAttemptsPath({ assessmentId}))}}
                >{jobTitle}</button>
            </div>
          </div>
        </header>
        <main>
          <div className={`mx-auto my-6 sm:mx-6 lg:mx-8`}>
            <div className={`ml-8 mr-2 rounded  text-sm font-medium bg-white shadow`}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Score
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Skills
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Decision
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <ViewAttemptRow 
                    score={score}
                    assessmentDecision={decision}
                    attemptId={attemptId}
                    assessmentId={assessmentId}
                    questions={questions}
                    numQuestions={numQuestions}
                  />
                </tbody>
              </table>

            </div>
          </div>
          <QuestionList dragAndDrop={false}>
          {questions.map((question, index) => {
              return (
                <QuestionFromAttempt
                  key={index}
                  question={question}
                  index={index}
                />
            )}
          )}
          </QuestionList>
        </main>
      </div>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
      />
      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>

  )
}

export default ViewAttemptPage;