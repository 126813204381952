import { useStore } from '../store/store';
import { updateAccount } from "../actions/accounts";
import { spinnerIcon, exclamationIcon } from "../helpers/icons";
import {useState} from 'react';
// import amplitude from 'amplitude-js';

const ProfileTab = () => {

  const account = useStore(state => state.account);
  const user = useStore(state => state.user);
  const setAccount = useStore(state => state.setAccount);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState(account.name ? account.name : '');
  const [title, setTitle] = useState(account.title ? account.title : '');


  const handleClick = () => {
    if (!name) {
      setErrorMessage('Name cannot be blank')
    } else if (!title) {
      setErrorMessage('Title cannot be blank')
    } else {
      setButtonLoading(true);
      console.log({ name, title })
      updateAccount({ user, account: { name, title } }).then(() => {
        // amplitude.getInstance('client').logEvent('updateAccount', { name, title });
        setAccount({ ...account, name, title });
        setButtonLoading(false);
        setErrorMessage('');
      }).catch((error) => {
        console.log(error.errorCode, error.errorMessage)
        setErrorMessage('Account could not be updated.');
        setButtonLoading(false);
      })
    }
  }


  return (
    <div className="mt-8 space-y-4">
      {/* Name */}
      <div>
        <label htmlFor="name" className="text-sm font-semibold">Full name</label>
        <input
          id="name"
          name="name"
          type="text"
          autoComplete="name"
          required
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          // placeholder="Bruce Wayne"
          value={name}
          onChange={(e) => { setName(e.target.value) }}
          disabled={buttonLoading}
        />
      </div>


      {/* title */}
      <div>
        <label htmlFor="title" className="text-sm font-semibold">Title</label>
        <input
          id="title"
          name="title"
          type="text"
          autoComplete="password"
          required
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          // placeholder="Software engineer"
          value={title}
          onChange={(e) => { setTitle(e.target.value) }}
          disabled={buttonLoading}
        />
      </div>

      {/* Email */}
      <div>
        <label htmlFor="email" className="text-sm font-semibold">Email</label>
        <input
          id="email"
          name="email"
          type="text"
          autoComplete="email"
          required
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          value={account.email}
          disabled={true}
        />
      </div>

      {/* Organization name */}
      <div>
        <label htmlFor="orgName" className="text-sm font-semibold">Organization name</label>
        <input
          id="orgName"
          name="orgName"
          type="text"
          autoComplete="organization"
          required
          className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
          // placeholder="Wayne Industries"
          value={account.orgName}
          disabled={true}
        />
      </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={(e) => { handleClick(e.target.value) }}
          disabled={buttonLoading}
        >
          {buttonLoading ? spinnerIcon :
            (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
              {/* {lockIcon} */}
            </span>)
          }
          {buttonLoading ? '' : 'Update your account'}
        </button>
      </div>

      {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
    </div>
  )
}

export default ProfileTab;