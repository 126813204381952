const ProgressBar = ({totalSteps, currentStep}) => {
  const completed = (currentStep -1) / totalSteps * 100;
  // console.log({currentStep, totalSteps})

  const containerStyles = {
    height: 8,
    // width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    // margin: 50,
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: "#3b82f6",
    // transition: 'width 1s ease-in-out',
    borderRadius: 'inherit',
    // textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}></span>
        </div>
      </div>
    </div>

  );
};

export default ProgressBar;