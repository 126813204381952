export const sortQuestions = ({ questions, questionOrder }) => {
  if (!questions) return [];
  if (!questionOrder) return questions;

  let questionsMap = {};
  questions.forEach((question) => {
    questionsMap[question.questionId] = question;
  });

  let newQuestions = [];
  questionOrder.map((questionId) => {
    let question = questionsMap[questionId];
    delete questionsMap[questionId];
    newQuestions.push(question);
  });

  let remainingQuestions = Object.values(questionsMap);
  if (remainingQuestions.length > 0) {
    remainingQuestions.forEach((question) => {
      newQuestions.push(question);
    })
  }
  return newQuestions;
}

export const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const returnScore = ({numCorrect, numQuestions}) => {
  let score = numCorrect / numQuestions
  score = score * 100;
  score = score.toFixed(2);
  score = parseInt(score, 10);
  return score;
}