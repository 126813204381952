import { homePath, signPath } from '../helpers/urls';
import { useNavigate } from 'react-router-dom';

const FallbackPage = ({ message = "Page not found", loginButton = false }) => {

  let navigate = useNavigate();

  return (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <h1 className="text-4xl font-semibold">{message}</h1>
        {/* <p className="text-gray-700">Please check the URL in the address bar and try again.</p> */}
        {!loginButton ? (
          <button
            className="bg-blue-500 rounded-lg hover:bg-blue-600 font-semibold text-white p-3"
            onClick={() => { 
              navigate(homePath);
              window.location.reload()
            }}
            // onClick={() => { window.open('/', '_blank')}}
          >
            Go back home
          </button>
        ) : (
          <button
            className="bg-blue-500 rounded-lg hover:bg-blue-600 font-semibold text-white p-3"
            onClick={() => { navigate(signPath) }}
          >
            Log in
          </button>
        )}
      </div>
    </div>
  )
}

export default FallbackPage;