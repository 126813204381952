import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { spinnerIcon, exclamationIcon, plusIcon } from "../helpers/icons";
import { useStore } from '../store/store';
// import { useNavigate } from 'react-router-dom';
import {saveQuestion} from '../actions/assessments';
// import {createAssessment} from '../actions/assessments';
// import { returnAssessmentPath } from '../helpers/urls';
import EditChoice from './EditChoice';
import SkillSelect from './SkillSelect';
import { capitalizeFirstLetters } from '../helpers/validation';
const { v4: uuid } = require('uuid');


const EditQuestionModal = ({ 
  open, 
  setOpen, 
  questionId, 
  initialQueryText, 
  initialSeconds, 
  initialChoices, 
  initialSkills, 
  assessmentId, 
  setLoadAssessmentPage,
  returnAllAssessmentSkills
}) => {

  const user = useStore(state => state.user);
  const [queryText, setQueryText] = useState(initialQueryText);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [choices, setChoices] = useState(initialChoices);
  // const createSkillsArrayFromObj = ({ skills }) => {
  //   return Object.entries(skills).map((skill) => {
  //     return {skillId: skill[0], skillName: skill[1]}
  //   })
  // }
  // const [skillsArray, setSkillsArray] = useState(createSkillsArrayFromObj({skills: initialSkills}));
  // const [skills, setSkills] = useState(initialSkills);
  // console.log({initialSkills, skills})

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const textAreaRef = useRef(null);
  // const [skills, setSkills] = useState(skillsArray);
  const [skills, setSkills] = useState(initialSkills);
  // let navigate = useNavigate();

  const handleChangeChecked = ({choiceIndex}) => {
    let newChoices = choices.map(({choiceId, choiceText, correct}, index) => {
      return {
        choiceId, choiceText, correct: choiceIndex === index 
      }
    })
    setChoices(newChoices);
  }

  const handleChangeChoiceText = ({choiceIndex, newChoiceText}) => {
    let newChoices = choices.map(({ choiceId, choiceText, correct }, index) => {
      return {
        choiceId, correct, choiceText: choiceIndex !== index ? choiceText : newChoiceText
      }
    })
    setChoices(newChoices);
  }

  const handleAddChoice = () => {
    const choiceId = uuid();
    const correct = choices.length === 0;
    const choiceText = '';
    let newChoices = [...choices, { choiceId, correct, choiceText }];
    setChoices(newChoices);
  }

  const handleRemoveChoice = ({choiceIndex}) => {
    let newChoices = choices.filter((choice, index) => {return index !== choiceIndex});
    setChoices(newChoices);
  }

  const handleSaveQuestion = () => {
    // if (!validateForm()) return;
    setButtonLoading(true);
    saveQuestion({ 
      user, 
      questionId, 
      queryText, 
      seconds: seconds ? seconds : 0, 
      choices, 
      // skills: createSkillsObjFromArray({ skillsArray }),
      skills,
      assessmentId 
    }).then((data) => {
      // amplitude.getInstance('client').logEvent('askQuestion', { questionText, questionId: data.questionId });
      setButtonLoading(false);
      setOpen(false);
      setErrorMessage('');
      setLoadAssessmentPage(state => state +1);
    //   navigate(returnAssessmentPath({ assessmentId: data.assessmentId }));
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }

  const handleCreateSkill = (label) => {
    // const newSkill = {
    //   skillId: uuid(),
    //   skillName: label,
    // }
    // setSkillsArray([...skillsArray, newSkill])
    setSkills([...skills, capitalizeFirstLetters(label)]);
  }

  const handleChangeSkills = (options) => {
    // let newSkills = [];
    // options.forEach(({ value, label }) => {
    //   newSkills.push({
    //     skillId: value,
    //     skillName: label
    //   })
    // })
    // setSkillsArray(newSkills);
    let newSkills = options.map(({ value, label }) => capitalizeFirstLetters(label))
    setSkills([...newSkills]);
  }

  // const createSkillsObjFromArray = ({skillsArray}) => {
  //   let result = {};
  //   skillsArray.forEach(({ skillId, skillName }) => {
  //     result[skillId] = skillName;
  //   });
  //   return result;
  // }

  useEffect(() => {
    const reset = () => {
      setQueryText(initialQueryText);
      setSeconds(initialSeconds);
      setChoices(initialChoices);
      setSkills(initialSkills);
      setErrorMessage('');
    }
    if (!open) reset();
    return reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Modify this question
                    </Dialog.Title>
                    <div className="mt-4 w-96">
                      <div className="mt-4">
                        <label htmlFor="name" className="text-sm font-semibold">Question</label>
                        <textarea
                          className="border rounded resize-none w-full my-2 p-2 sm:text-sm"
                          autoFocus
                          type="text"
                          rows="5"
                          value={queryText}
                          onChange={(e) => { setQueryText(e.target.value) }}
                          placeholder="Sales Development Representative"
                          ref={textAreaRef}
                        />
                      </div>

                      <div className="">
                        <div className="text-sm font-semibold">Choices</div>
                        {!choices || choices.length === 0 ? '' : choices.map(({choiceId, choiceText, correct}, index) => {
                          return <EditChoice
                            key={choiceId}
                            choiceId={choiceId}
                            choiceText={choiceText}
                            correct={correct}
                            handleChangeChoiceText={handleChangeChoiceText}
                            handleChangeChecked={handleChangeChecked}
                            handleRemoveChoice={handleRemoveChoice}
                            choiceIndex={index}
                          />
                        })}
                      </div>

                      <div className="flex justify-end">

                        <div className="w-full flex justify-between mb-2">
                          <button
                            className="text-xs flex items-center rounded"
                            onClick={() => { handleAddChoice() }}
                          >
                            <span className="rounded-full text-gray-500 hover:bg-gray-200 p-0.5">{plusIcon}</span>
                            {/* <span className="text-gray-500 ml-3">Add a choice</span> */}
                          </button>
                        </div>
                      </div>


                      <div className="mt-4">
                        {/* <div className="text-sm font-semibold my-2 border-t pt-2">Time limit</div> */}

                        <div className="text-sm font-semibold my-2">Skills</div>
                        <SkillSelect 
                          handleCreateSkill={handleCreateSkill}
                          handleChangeSkills={handleChangeSkills}
                          // skillsArray={skillsArray}
                          skills={skills}
                          returnAllAssessmentSkills={returnAllAssessmentSkills}
                        />
                      </div>

                      {/* <div className="text-sm font-semibold my-2 border-t pt-2">Time limit</div> */}
                      <div className='flex items-center mt-2'>
                        {/* <button
                            className="hover:bg-gray-100 rounded-full"
                          >
                            {clockIcon}
                          </button> */}
                        <input
                          className="border rounded resize-none p-1 sm:text-sm w-14"
                          type="number"
                          rows="1"
                          value={seconds}
                          onChange={(e) => {
                            let val = e.target.value;
                            val = parseInt(val);
                            setSeconds(val)}}
                        />
                        <span className="ml-2">
                          <div className="text-gray-700 text-xs whitespace-nowrap">second limit</div>
                        </span>
                      </div>

                      <div className="w-full flex justify-end">

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { handleSaveQuestion() }}
                  disabled={buttonLoading}
                >
                  <span className={buttonLoading ? "invisible" : ''}>Save</span>
                  {buttonLoading ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
  
}

export default EditQuestionModal;