// @ts-nocheck

import './App.css';
import AppRouter from './routers/AppRouter';
import { onAuthStateChanged } from "firebase/auth";
import { useStore } from './store/store';
import { useState, useEffect } from 'react';
import { auth } from './firebase/firebase';
import LoadingPage from './components/LoadingPage';
import {getAccount} from './actions/accounts';
import CreateOrgPage from './components/CreateOrgPage';
import NavRoute from './routers/NavRoute';

function App() {

  const [loading, setLoading] = useState(true);
  const setUser = useStore(state => state.setUser);
  const user = useStore(state => state.user);
  // const orgIsBeingCreated = useStore(state => state.orgIsBeingCreated);
  const uid = user ? user.uid : null;
  const [authLoading, setAuthLoading] = useState(true);
  const setAccount = useStore(state => state.setAccount);
  const [orgExists, setOrgExists] = useState(true);



  onAuthStateChanged(auth, async (user) => {
    if (user) {
      setUser(user);
      // amplitude.getInstance('client').setUserId(user.uid);
      setAuthLoading(false);
    } else {
      setUser(null);
      // amplitude.getInstance('client').setUserId(null);
      setAuthLoading(false);
    }
  });

  const creatingAccount = useStore(state => state.creatingAccount);

  // When user logs in, init data
  useEffect(() => {
    let isMounted = true;

    const setUpApp = async () => {

      if (authLoading) {
        return;
      } else if (!uid) {
        // setAlgoliaLoading(false);
        // setProfileImagesListLoading(false)
        // setProfileImageCroppedLoading(false);
        return setLoading(false);
      } else {
        // Reset loading
        // setAlgoliaLoading(true);
        // setProfileImagesListLoading(true)
        // setProfileImageCroppedLoading(true);

        // 1. Get account data
        await getAccount({ user })
          .then((results) => {
            if (!isMounted) return;
            setAccount({ ...results });
            // amplitude.getInstance('client').setUserProperties({ account });
            console.log(`Got account: ${JSON.stringify(results)}.  ${authLoading} ${loading}`)
            setLoading(false);
          })
          .catch((error) => {
            if (!isMounted) return;
            console.log('No org exists.  Going to create org page.');
            setOrgExists(false);
            setLoading(false);
            console.log('Stopping execution because account not found.')
            return // Stop execution if account is not found.
          })

        // 2. Get algolia public key
        // await getAlgoliaPublicKey({ user }).then((data) => {
        //   if (!isMounted) return;
        //   const { algoliaPublicKey } = data;
        //   setAlgoliaPublicKey(algoliaPublicKey);
        //   setAlgoliaLoading(false);
        // }).catch((error) => {
        //   if (!isMounted) return;
        //   console.log({ error });
        //   setAlgoliaLoading(false);
        //   setFallback(true);
        // });

        // 3. Get list of others users who have uploaded profileImages
        // await getProfileImagesList({ user })
        //   .then((results) => {
        //     if (!isMounted) return;
        //     setProfileImagesList(results?.profileImagesList);
        //     return results?.profileImagesList;
        //   })
        //   .catch((error) => {
        //     if (!isMounted) return;
        //     console.log({ error });
        //     // setProfileImagesListLoading(false);
        //     setFallback(true);
        //   }).then((profileImagesList) => {
        //     if (!isMounted) return;

        //     // 3.5 Get urls to all of their profile images and store them in the store.
        //     let promises = Object.entries(profileImagesList).filter((elem) => {
        //       return !!elem[1].profileImageUploaded && !elem[1]?.url
        //     }).map((elem) => {
        //       return new Promise(async (resolve, reject) => {
        //         await getProfileImageCroppedUrl({ orgId: account.orgId, uid: elem[0] })
        //           .then((result) => {
        //             if (!isMounted) return;
        //             setProfileImagesListUrl({ answeredBy: elem[0], url: result })
        //             resolve()
        //           }).catch((error) => {
        //             if (!isMounted) return;
        //             console.log({ error }, `Some calls to cloud storage didn't work.`)
        //             setProfileImagesListUrl({ answeredBy: elem[0], url: null })
        //             resolve() // Will resolve the promise EVEN IF some calls to cloud storage didn't work.
        //           })
        //       })
        //     })
        //     Promise.all(promises).then(() => {
        //       if (!isMounted) return;
        //       setProfileImagesListLoading(false);
        //       console.log('Retrieved all profile images links from cloud storage');
        //     }).catch((error) => {
        //       if (!isMounted) return;
        //       console.log('Error retrieving profile image links from cloud storage');
        //     })
        //   })

        // 4. Get profileImageCroppedUrl for this user
        // await getProfileImageCroppedUrl({ orgId: account.orgId, uid })
        //   .then((result) => {
        //     if (!isMounted) return;
        //     setProfileImageCroppedUrl(result);
        //     setProfileImageCroppedLoading(false);
        //   }).catch((error) => {
        //     if (!isMounted) return;
        //     console.log({ error })
        //     setProfileImageCroppedLoading(false);
        //     setFallback(false);
        //   })
      }
    }

    setUpApp();
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, authLoading, creatingAccount]);


  if (authLoading || loading) {
    return <LoadingPage />
  } else if (!orgExists) {
    return <NavRoute authRequired={true}><CreateOrgPage /></NavRoute>
  } else {
    return <AppRouter />
  }
}

export default App;
