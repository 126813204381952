import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { spinnerIcon, exclamationIcon } from "../helpers/icons";
import { useStore } from '../store/store';
import { useNavigate } from 'react-router-dom';
import {createAssessment} from '../actions/assessments';
import { returnAssessmentPath } from '../helpers/urls';

const CreateAssessmentModal = ({ open, setOpen }) => {

  const user = useStore(state => state.user);
  const [jobTitle, setJobTitle] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const cancelButtonRef = useRef(null)
  const textAreaRef = useRef(null);
  let navigate = useNavigate();


  const validateForm = () => {
    let validated = false
    if (!jobTitle) {
      setErrorMessage('Please enter a job title');
    } else {
      validated = true;
      setErrorMessage('');
    }
    return validated;
  }

  const handleSave = () => {
    if (!validateForm()) return;
    setButtonLoading(true);
    createAssessment({ user, jobTitle }).then((data) => {
      // amplitude.getInstance('client').logEvent('askQuestion', { questionText, questionId: data.questionId });
      setButtonLoading(false);
      setOpen(false);
      setErrorMessage('');

      navigate(returnAssessmentPath({ assessmentId: data.assessmentId }));
    }).catch(error => {
      error = JSON.parse(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }

  useEffect(() => {
    if (!open) setJobTitle('');
    return () => {
      setJobTitle('');
    }
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Create an assessment
                    </Dialog.Title>
                    <div className="mt-4 w-96">

                      <div className="my-4">
                        <label htmlFor="name" className="text-sm font-semibold">What role are you hiring for?</label>
                        <textarea
                          className="border rounded resize-none w-full my-2 p-2 sm:text-sm"
                          autoFocus
                          type="text"
                          rows="1"
                          value={jobTitle}
                          onChange={(e) => { setJobTitle(e.target.value) }}
                          placeholder="Sales Development Representative"
                          ref={textAreaRef}
                        />
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { handleSave() }}
                  disabled={buttonLoading}
                >
                  <span className={buttonLoading ? "invisible" : ''}>Create assessment</span>
                  {buttonLoading ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CreateAssessmentModal;