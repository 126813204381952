import {navbarHeight} from '../helpers/constants';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {homePath, attemptsPath, assessmentsPath, takeAsssessmentPath} from '../helpers/urls';
import { useStore } from '../store/store';
import { useState } from 'react';
import AccountMenu from './AccountMenu';
import { userCornerIcon, smallChevronLeftIcon } from '../helpers/icons';

const Navbar = () => {

  let navigate = useNavigate();
  const user = useStore(state => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation()
  let { assessmentId, attemptId } = useParams();
  const takingAssessment = location.pathname.substring(0, takeAsssessmentPath?.length) === takeAsssessmentPath;
  // const { name, role } = useStore(state => state.account);

  return (
    // <div className={`bg-gray-700 h-${navbarHeight} px-4 w-full flex justify-between sticky top-0 z-50`}>
    <div className={`bg-gray-700 h-${navbarHeight} px-4 w-full flex justify-between`}>
      <div className="flex items-center space-x-6">
        <button
          className="col-span-4 text-white font-semibold flex items-center gap-1"
          onClick={() => { navigate(homePath) }}
        >
          <img src='/feather-white.png' className="w-6 h-6" alt="Skill Feather" /> 
          <span className="text-2xl p-0.5">Skill Feather</span>
        </button>
        {/* {
          !user?.uid || location.pathname.substring(0, 12) !== '/assessments' ? '' : 
          <button
            className="group relative text-white ml-4 py-1 pr-1.5 border border-white rounded text-xs mt-1 flex hover:bg-gray-600"
            onClick={() => { navigate(homePath) }}
          >
            <span>{smallChevronLeftIcon}</span>
            <span>Assessments</span>
          </button>
        } */}
        {!user?.uid || takingAssessment ? '' : <button
          className={`text-sm py-1 px-2 rounded hover:bg-black hover:text-white  ${location.pathname === assessmentsPath ? ' text-white shadow-lg font-semibold bg-black' : 'text-gray-100 font-light'}`}
          onClick={() => {navigate(assessmentsPath)}}
        >
          Assessments
        </button>}
        {!user?.uid || takingAssessment ? '' : <button
          className={`text-sm py-1 px-2 rounded hover:bg-black hover:text-white  ${location.pathname.substring(0, attemptsPath.length) === attemptsPath ? ' text-white shadow-lg font-semibold bg-black' : 'text-gray-100 font-light'}`}
          onClick={() => { navigate(attemptsPath) }}
        >
          Attempts
        </button>}
        {(user?.uid && 
          location.pathname.substring(0, assessmentsPath?.length) === assessmentsPath && 
          assessmentId?.length === 36
        ) ? <div
          className={`text-sm py-1 px-2 rounded border text-gray-100 font-light`}
        >
          Editing assessment
        </div> : ''}
        {(user?.uid &&
          location.pathname.substring(0, attemptsPath?.length) === attemptsPath &&
          attemptId?.length === 36
        ) ? <div
          className={`text-sm py-1 px-2 rounded border text-gray-100 font-light`}
        >
          Viewing attempt
        </div> : ''}
        {/* <div className="hidden sm:block sm:ml-6">
          <div className="flex space-x-4">
              <button
                className={'bg-gray-900 text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'}
                // aria-current={item.current ? 'page' : undefined}
              >
                Assessments
              </button>
          </div>
        </div> */}


      </div>

      {takingAssessment ? '' : (
        <div className="text-white font-semibold flex justify-end items-center mx-4">
          <button
            className="rounded-full w-6 inline-block"
            onClick={() => { setMenuOpen(state => !state) }}
          >
            <div className="text-white ">{userCornerIcon}</div>
          </button>
          {!menuOpen ? '' : (
            <div>
              <AccountMenu open={menuOpen} setOpen={setMenuOpen} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Navbar;