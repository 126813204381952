import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { spinnerIcon, exclamationIcon, plusIcon } from "../helpers/icons";
import { useStore } from '../store/store';
import {createQuestion} from '../actions/assessments';
// import { returnAssessmentPath } from '../helpers/urls';
import EditChoice from './EditChoice';
import SkillSelect from './SkillSelect';
import { capitalizeFirstLetters } from '../helpers/validation';
const { v4: uuid } = require('uuid');


const AddQuestionModal = ({ 
  open, 
  setOpen, 
  assessmentId, 
  setLoadAssessmentPage,
  returnAllAssessmentSkills
}) => {


  const user = useStore(state => state.user);
  const [queryText, setQueryText] = useState('');
  const [seconds, setSeconds] = useState(30);
  const initialChoices = [{
    choiceId: uuid(),
    choiceText: '',
    correct: true
  }]
  const [choices, setChoices] = useState(initialChoices);
  // const [skills, setSkills] = useState({});

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const textAreaRef = useRef(null);
  // const [skills, setSkills] = useState(skillsArray);
  const [skills, setSkills] = useState([]);


  const handleChangeChecked = ({choiceIndex}) => {
    let newChoices = choices.map(({choiceId, choiceText, correct}, index) => {
      return {
        choiceId, choiceText, correct: choiceIndex === index 
      }
    })
    setChoices(newChoices);
  }

  const handleChangeChoiceText = ({choiceIndex, newChoiceText}) => {
    let newChoices = choices.map(({ choiceId, choiceText, correct }, index) => {
      return {
        choiceId, correct, choiceText: choiceIndex !== index ? choiceText : newChoiceText
      }
    })
    setChoices(newChoices);
  }

  const handleAddChoice = () => {
    const choiceId = uuid();
    const correct = choices.length === 0;
    const choiceText = '';
    let newChoices = [...choices, { choiceId, correct, choiceText }];
    setChoices(newChoices);
  }

  const handleRemoveChoice = ({choiceIndex}) => {
    let newChoices = choices.filter((choice, index) => {return index !== choiceIndex});
    setChoices(newChoices);
  }

  const validateSaveQuestionForm = () => {
    let validated = false
    if (!user.uid) {
      setErrorMessage('You must be logged in.');
    } else if (!queryText) {
      setErrorMessage('The question cannot be blank.');
    } else if (!seconds || seconds > 300 || seconds < 0) {
      setErrorMessage('You must choose a time limit between 0 and 300.');
    } else if (!choices || choices.length === 0) {
      setErrorMessage('You must add answer choices.');
    } else if (choices.filter(({correct}) => correct).length !== 1) {
      setErrorMessage('You must choose one correct choice.');
    } else if (choices.filter(({ choiceText }) => choiceText === '').length > 0) {
      setErrorMessage('You cannot have blank answer choices.');
    // } else if (!skills || Object.values(skills).length) {
    //   setErrorMessage('You must choose relevant skills.');
    } else if (!assessmentId) {
      setErrorMessage('You must provide an assessmentId.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleSaveQuestion = () => {
    if (!validateSaveQuestionForm()) return;

    // if (!validateForm()) return;
    setButtonLoading(true);
    createQuestion({ 
      user, 
      queryText, 
      seconds, 
      choices, 
      skills,
      assessmentId 
    }).then((data) => {
      // amplitude.getInstance('client').logEvent('askQuestion', { questionText, questionId: data.questionId });
      setButtonLoading(false);
      setOpen(false);
      setErrorMessage('');
      setLoadAssessmentPage(state => state +1);
    //   navigate(returnAssessmentPath({ assessmentId: data.assessmentId }));
    }).catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
      setErrorMessage(errorMessage);
      setButtonLoading(false);
    })
  }

  const handleCreateSkill = (label) => {
    setSkills([...skills, capitalizeFirstLetters(label)]);
  }

  const handleChangeSkills = (options) => {
    let newSkills = options.map(({ value, label }) => capitalizeFirstLetters(label))
    setSkills([...newSkills]);
  }

  useEffect(() => {
    const reset = () => {
      setQueryText('');
      setSeconds(30);
      setChoices(initialChoices);
      setSkills([]);
      setErrorMessage('');
    }
    if (!open) reset();
    return reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Add a question
                    </Dialog.Title>
                    <div className="mt-4 w-96">
                      <div className="mt-4">
                        <label htmlFor="name" className="text-sm font-semibold">Question</label>
                        <textarea
                          className="border rounded resize-none w-full my-2 p-2 sm:text-sm"
                          autoFocus
                          type="text"
                          rows="5"
                          value={queryText}
                          onChange={(e) => { setQueryText(e.target.value) }}
                          placeholder="Sales Development Representative"
                          ref={textAreaRef}
                        />
                      </div>

                      <div className="">
                        <div className="text-sm font-semibold">Choices</div>
                        {!choices || choices.length === 0 ? '' : choices.map(({choiceId, choiceText, correct}, index) => {
                          return <EditChoice
                            key={choiceId}
                            choiceId={choiceId}
                            choiceText={choiceText}
                            correct={correct}
                            handleChangeChoiceText={handleChangeChoiceText}
                            handleChangeChecked={handleChangeChecked}
                            handleRemoveChoice={handleRemoveChoice}
                            choiceIndex={index}
                          />
                        })}
                      </div>

                      <div className="w-full flex justify-between">

                        

                        <div className="w-full flex justify-between mb-2">
                          <button
                            className="text-xs flex items-center rounded"
                            onClick={() => { handleAddChoice() }}
                          >
                            <span className="rounded-full text-gray-500 hover:bg-gray-200 p-0.5">{plusIcon}</span>
                            {/* <span className="text-gray-500 ml-3">Add a choice</span> */}
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="text-sm font-semibold mb-1">Skills</div>
                        <SkillSelect
                          handleCreateSkill={handleCreateSkill}
                          handleChangeSkills={handleChangeSkills}
                          // skillsArray={skillsArray}
                          skills={skills}
                          returnAllAssessmentSkills={returnAllAssessmentSkills}
                        />
                      </div>

                      <div className='flex items-center mt-2'>
                        {/* <button
                            className="hover:bg-gray-100 rounded-full"
                          >
                            {clockIcon}
                          </button> */}
                        <input
                          className="border rounded resize-none p-1 sm:text-sm w-14"
                          type="number"
                          rows="1"
                          value={seconds}
                          onChange={(e) => {
                            let val= e.target.value;
                            val = parseInt(val);
                            setSeconds(val)
                          }}
                        />
                        <span className="ml-2">
                          <div className="text-gray-700 text-xs whitespace-nowrap">second limit</div>
                        </span>
                      </div>

                      <div className="w-full flex justify-end">

                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { handleSaveQuestion() }}
                  disabled={buttonLoading}
                >
                  <span className={buttonLoading ? "invisible" : ''}>Save</span>
                  {buttonLoading ? <span className="absolute m-auto text-center">{spinnerIcon}</span> : ""}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
  
}

export default AddQuestionModal;