import {useEffect, useState} from 'react';
import { useStore } from '../store/store';
import { getAssessmentsList } from '../actions/assessments';
import LoadingPage from "./LoadingPage";
import AssessmentsTableRow from './AssessmentsTableRow';
import EmptyAssessmentsPage from './EmptyAssessmentsPage';
import CreateAssessmentButton from './CreateAssessmentButton';
import { useSearchParams } from 'react-router-dom';

const AssessmentsPage = () => {

  const [open, setOpen] = useState(false);
  const [assessmentsListLoading, setAssessmentsListLoading] = useState(false);
  const [assessmentsList, setAssessmentsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const user = useStore(state => state.user);
  const [createAssessmentFromScratchModalOpen, setCreateAssessmentFromScratchModalOpen] = useState(false);
  let [searchParams] = useSearchParams();
  let openCreateModal = searchParams.get("openCreateModal");
  const defaultOpen = openCreateModal === 'true'

  useEffect(() => {
    setAssessmentsListLoading(true);
    getAssessmentsList({ user }).then((assessmentsList) => {
      if (!assessmentsList || Object.values(assessmentsList).length === 0) {
        // console.log('No assessments viewable');
        setOpen(true);
        setAssessmentsListLoading(false);
      } else {
        setAssessmentsListLoading(false);
        let newAssessmentsList = Object.values(assessmentsList);
        setAssessmentsList(newAssessmentsList);
      }
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('Error downloading assessmentslist', errorCode, errorMessage)
      setErrorMessage(errorMessage);
      // setOrgIsBeingCreated(false);
      setAssessmentsListLoading(false);
      // setLoading(false);
      // setFallback(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateAssessment = () => {
    setOpen(true);
  }


  if (assessmentsListLoading) {
    return <LoadingPage />
  } else if (assessmentsList.length === 0) {
    return <EmptyAssessmentsPage
      handleCreateAssessment={handleCreateAssessment}
      open={open}
      setOpen={setOpen}
      createAssessmentFromScratchModalOpen={createAssessmentFromScratchModalOpen}
      setCreateAssessmentFromScratchModalOpen={setCreateAssessmentFromScratchModalOpen}
    />
  } else {
    
    return (
      <div className=" bg-gray-50 min-h-screen-adj">
        <header className="shadow">
          <div className="pt-6 pb-2 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="text-3xl font-semibold">Your assessments</div>
            <CreateAssessmentButton defaultOpen={defaultOpen} />
          </div>
        </header>
        <main>
          {/* <div className="w-full pt-4 text-sm bg-gray-50 min-h-screen-adj"> */}
          <div className="flex flex-col px-4 sm:px-6 lg:px-8 mt-4">

          {/* Assessments Table */}
          <div className="my-1 overflow-x-auto ">
            <div className="py-2 align-middle inline-block min-w-full">
              <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Job
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Updated
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Published
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Attempts
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {
                      assessmentsList.map(({ jobTitle, dateUpdated, published, createdBy, numAttempts, numDecided, numYes, numNo, assessmentId }, index) => {
                        return <AssessmentsTableRow
                          key={index}
                          jobTitle={jobTitle}
                          dateUpdated={dateUpdated}
                          published={published}
                          createdBy={createdBy}
                          numAttempts={numAttempts}
                          numDecided={numDecided}
                          numYes={numYes}
                          numNo={numNo}
                          assessmentId={assessmentId}
                        />
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          {errorMessage}
          {/* </div> */}
        </main>
      </div>

      // <div className="w-full pt-4 text-sm bg-gray-50 min-h-screen-adj">
      //   <div className="flex flex-col mx-12 mt-4">
      //     <div className="w-full flex justify-between items-center">
      //       <div className="text-3xl font-semibold">Your assessments</div>
      //       <CreateAssessmentButton defaultOpen={defaultOpen} />
      //     </div>
      //     {/* Assessments Table */}
      //     <div className="my-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
      //       <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      //         <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
      //           <table className="min-w-full divide-y divide-gray-200">
      //             <thead className="bg-gray-200">
      //               <tr>
      //                 <th
      //                   scope="col"
      //                   className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //                 >
      //                   Job
      //                 </th>
      //                 <th
      //                   scope="col"
      //                   className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //                 >
      //                   Updated
      //                 </th>
      //                 <th
      //                   scope="col"
      //                   className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //                 >
      //                   Published
      //                 </th>
      //                 <th
      //                   scope="col"
      //                   className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //                 >
      //                   Attempts
      //                 </th>
      //                 <th
      //                   scope="col"
      //                   className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      //                 >
      //                   Actions
      //                 </th>
      //               </tr>
      //             </thead>
      //             <tbody className="bg-white divide-y divide-gray-200">
      //               {
      //                 assessmentsList.map(({ jobTitle, dateUpdated, published, createdBy, numAttempts, numDecided, numYes, numNo, assessmentId }, index) => {
      //                   return <AssessmentsTableRow
      //                     key={index}
      //                     jobTitle={jobTitle}
      //                     dateUpdated={dateUpdated}
      //                     published={published}
      //                     createdBy={createdBy}
      //                     numAttempts={numAttempts}
      //                     numDecided={numDecided}
      //                     numYes={numYes}
      //                     numNo={numNo}
      //                     assessmentId={assessmentId}
      //                   />
      //                   return <div>test</div>
      //                 })
      //               }
      //             </tbody>
      //           </table>
      //         </div>
      //       </div>
      //     </div>
          
      //   </div>
      // </div>
    )
  }

}

export default AssessmentsPage;