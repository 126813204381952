import create from 'zustand'
// import produce from 'immer';

export const useStore = create(set => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  account: null,
  setAccount: (account) => set(() => ({ account })),
  creatingAccount: false,
  setCreatingAccount: (creatingAccount) => set(() => ({ creatingAccount })),
  email: '',
  setEmail: (email) => set(() => ({email})),
  draggingQuestion: false,
  setDraggingQuestion: (draggingQuestion) => set(() => ({ draggingQuestion }))
}))