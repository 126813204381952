import { apiUrl } from '../helpers/urls';

export const createAssessment = async ({ user, jobTitle, skills }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ jobTitle, skills })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            const errorObj = JSON.parse(error);
            console.log({ errorObj })
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getAssessment = async ({ user, assessmentId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments/${assessmentId}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const publishAssessment = async ({ user, assessmentId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/publishedassessments`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assessmentId })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const unpublishAssessment = async ({ user, assessmentId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/unpublishedassessments`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ assessmentId })
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

export const getAssessmentsList = async ({ user }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments/list`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
    }).then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({ data });
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    }).catch((error) => {
      reject(error);
    });
  });
}

// export const addBlankQuestionToAssessment = async ({
//   user,
//   assessmentId
// }) => {
//   const idToken = !user ? null : await user.getIdToken();
//   return new Promise((resolve, reject) => {
//     fetch(`${apiUrl}/assessment/addBlankQuestion`, {
//       method: 'PATCH',
//       headers: {
//         'authorization': `Bearer ${idToken}`,
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         assessmentId
//       })
//     })
//       .then(res => {
//         if (res.ok) {
//           console.log('res', res);
//           res.json().then(data => {
//             console.log({ data });
//             resolve(data)
//           }).catch((error) => {
//             console.log(error);
//           });
//         } else {
//           res.text().then(text => reject(text));
//         };
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export const createQuestion = async ({
  user,
  queryText,
  seconds,
  choices,
  skills,
  assessmentId
}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments/questions`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assessmentId,
        queryText,
        seconds,
        choices,
        skills,
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const saveQuestion = async ({
  user,
  questionId,
  queryText,
  seconds,
  choices,
  skills,
  assessmentId
}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments/questions`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        questionId,
        queryText,
        seconds,
        choices,
        skills,
        assessmentId
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const deleteQuestion = async ({ user, assessmentId, questionId }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments/questions`, {
      method: 'DELETE',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        assessmentId, 
        questionId
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateAssessment = async ({
  user,
  jobTitle,
  questionOrder,
  assessmentId
}) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/assessments`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobTitle,
        questionOrder,
        assessmentId
      })
    })
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            console.log({ data });
            resolve(data)
          }).catch((error) => {
            console.log(error);
          });
        } else {
          res.text().then(text => reject(text));
        };
      })
      .catch((error) => {
        reject(error);
      });
  });
}