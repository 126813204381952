import { forwardRef } from "react";
import { useStore } from '../store/store';

const QuestionList = forwardRef(({ children, dragAndDrop = true, ...props }, ref) => {

  let draggingQuestion =  useStore(state => state.draggingQuestion);
  draggingQuestion = !dragAndDrop ? false : draggingQuestion;

  return (
    <ul ref={ref} className={`mx-auto my-6 sm:mx-6 lg:mx-8 ${!draggingQuestion ? '' : 'border border-gray-500 border-dashed rounded'}`}>
      {children}
    </ul>
  );
});

export default QuestionList;
