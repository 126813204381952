import {useState} from 'react';
import CreateAssessmentModal from "./CreateAssessmentModal";
import CreateAssessmentFromScratchModal from './CreateAssessmentFromScratchModal';

const CreateAssessmentButton = ({defaultOpen}) => {

  const [open, setOpen] = useState(defaultOpen);
  const [createAssessmentFromScratchModalOpen, setCreateAssessmentFromScratchModalOpen] = useState(false);



  const handleCreateAssessment = () => {
    setOpen(true);
  }

  return (
    <div>
      <div className="flex items-end">
        <button
          className={`rounded font-semibold p-2 my-2 bg-blue-500 hover:bg-blue-700 text-white`}
          // className="relative w-full flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => handleCreateAssessment()}
        >Create Assessment
        </button>
      </div>

      <CreateAssessmentModal open={open} setOpen={setOpen} setNextOpen={setCreateAssessmentFromScratchModalOpen} />
      <CreateAssessmentFromScratchModal open={createAssessmentFromScratchModalOpen} setOpen={setCreateAssessmentFromScratchModalOpen} />

    </div>
  )
}

export default CreateAssessmentButton;