import { clockIcon, trashIcon, pencilAltIcon, checkIcon } from '../helpers/icons';
import EditQuestionModal from './EditQuestionModal';
import DeleteQuestionModal from './DeleteQuestionModal';
import {useState, useRef, useEffect} from 'react';
import { useDrag, useDrop } from 'react-dnd'
import { useStore } from '../store/store';



const Question = ({ 
  index, 
  questionId, 
  queryText, 
  skills = [], 
  seconds, 
  choices, 
  choicesCollapsed, 
  assessmentId, 
  setLoadAssessmentPage, 
  handleDeleteQuestion, 
  moveListItem, 
  returnAllAssessmentSkills,
  deleteQuestionLoading,
  dragAndDrop = true,
  template = false
}) => {

  const [editQuestionModalOpen, setEditQuestionModalOpen] = useState(false);
  const [deleteQuestionModalOpen, setDeleteQuestionModalOpen] = useState(false);
  const setDraggingQuestion = useStore(state => state.setDraggingQuestion);

  // const skills = !!skills ? skills : []

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    setDraggingQuestion(isDragging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging])

  // useDrop - the list item is also a drop area
  const [/* spec */, dropRef] = useDrop({
    accept: 'item',
    hover: (item, monitor) => {
      const dragIndex = item.index
      const hoverIndex = index
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      moveListItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null)
  let dragDropRef = dragRef(dropRef(ref));
  dragDropRef = !dragAndDrop ? null : dragDropRef;

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0 : 1


  return (
    <div className="flex">
      <div className="mt-2 pt-1 mr-2 w-4 font-semibold rounded-full">{index + 1}</div> 
      <div
        key={questionId}
        className={`rounded w-full m-2 p-2 flex bg-white shadow ${dragAndDrop ? 'cursor-grab' : ''}`}
        ref={dragDropRef}
        style={{ opacity }}
      >
        <div className="w-full">
          <div className="font-light text-sm flex justify-between w-full display-linebreak border-b pb-4 mb-4">
            <div>{queryText}</div>
          </div>
          {!choices || choices.length === 0 ? '' : choices.map(({ choiceId, choiceText, correct }) => {
            return (
              <div key={choiceId} className="flex items-center my-2">
                {/* <input type="checkbox" checked={correct} onChange={() => { return }} /> */}
                <button
                  className={`rounded-full ${correct ? 'bg-blue-500' : 'bg-blue-500'} text-white p-1`}
                  disabled={true}
                  // checked={correct}
                  // onClick={() => { handleChangeChecked({ choiceIndex }) }}
                >
                  <span className={correct ? '' : 'invisible'}>{checkIcon}</span>
                </button>
                <label className="font-light text-sm mx-2 text-gray-700">{choiceText}</label>
              </div>
            )
          })}
          <div className="flex items-center my-1 border-t pt-2">
            {template ? '' : <button
              className="flex items-center text-gray-500 p-1 hover:bg-gray-100 rounded text-xs border"
              onClick={() => { setEditQuestionModalOpen(true) }}
            >
              {pencilAltIcon}
              <span className="pl-1">Edit question</span>
            </button>}
            {template ? '' : <button
              className="ml-2 flex items-center text-gray-500 p-1 hover:bg-gray-100 rounded text-xs border"
              // onClick={() => { handleDeleteQuestion({ assessmentId, questionId }) }}
              onClick={() => { setDeleteQuestionModalOpen(true) }}
            >
              {trashIcon}
              <span className="pl-1">Delete question</span>
            </button>}
            <span className="mx-2 flex items-center text-gray-500 border p-1 rounded">
              {clockIcon}
              <span className="text-xs  ml-1">{seconds} sec. limit</span>
            </span>
            {skills.map((skill) => {
              // const [skillId, skillName] = skill;
              return (
                <div
                  className="text-xs inline-block py-1 px-2.5 mr-1 leading-none text-center font-semibold bg-blue-100 text-blue-600 rounded-full"
                  key={skill}
                >
                  {skill}
                </div>
              )
            })}
          </div>

        </div>
        <EditQuestionModal
          open={editQuestionModalOpen}
          setOpen={setEditQuestionModalOpen}
          questionId={questionId}
          initialQueryText={queryText}
          initialSeconds={seconds}
          initialChoices={choices}
          initialSkills={skills}
          assessmentId={assessmentId}
          setLoadAssessmentPage={setLoadAssessmentPage}
          returnAllAssessmentSkills={returnAllAssessmentSkills}
        />
        <DeleteQuestionModal 
          open={deleteQuestionModalOpen}
          setOpen={setDeleteQuestionModalOpen}
          questionId={questionId}
          assessmentId={assessmentId}
          handleDelete={handleDeleteQuestion}
          buttonLoading={deleteQuestionLoading}
        />
      </div>
    </div>
  )
}

export default Question;