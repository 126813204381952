// import { useState } from 'react';
// import ImageUploader from './ImageUploader';
// import AccountNavbar from './AccountNavbar';
import ProfileTab from './ProfileTab';
// import NotificationsTab from './NotificationsTab';
// import IntegrationsTab from './IntegrationsTab';
// import amplitude from 'amplitude-js';
// import {accountPath} from '../helpers/urls';


const AccountPage = () => {

  const tab = 'profile';
  // const [tab, setTab] = useState('profile');

  // useEffect(() => {
    // amplitude.getInstance('client').logEvent(accountPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div className="min-h-screen-adj flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            View or update your information.
          </p>
        </div>

        {/* Profile image */}
        {/* <div>
          <ImageUploader />
        </div> */}

        {/* <AccountNavbar tab={tab} setTab={setTab} /> */}
        {tab !== 'profile' ? '' : <ProfileTab />}
        {/* {tab !== 'notifications' ? '' : <NotificationsTab />}
        {tab !== 'integrations' ? '' : <IntegrationsTab />} */}


      </div>
    </div>
  )
}

export default AccountPage;