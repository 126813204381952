import {apiUrl} from '../helpers/urls';

export const createOrg = async ({ user, orgName, title, name }) => {
  const idToken = !user ? null : await user.getIdToken();
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/orgs`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ orgName, name, title})
    })
    .then(res => {
      if (res.ok) {
        res.json().then(data => {
          console.log({data});
          resolve(data)
        }).catch((error) => {
          console.log(error);
        });
      } else {
        res.text().then(text => reject(text));
      };
    })
    .catch((error) => {
      reject(error);
    });
  });
}