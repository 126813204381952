import { spinnerIcon, chevronRightIcon, exclamationIcon } from "../helpers/icons";
import { useEffect, useState } from 'react';
import FallbackPage from './FallbackPage';
import { useNavigate } from 'react-router-dom';
import { homePath } from '../helpers/urls';
import {useStore} from '../store/store';
import {createOrg} from '../actions/orgs';



const CreateOrgPage = () => {

  let navigate = useNavigate();


  const [name, setName] = useState('');
  const [orgName, setOrgName] = useState('');
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const user = useStore(state => state.user);
  const setAccount = useStore(state => state.setAccount);
  const [fallback, setFallback] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const validateSignupForm = () => {
    let validated = false
    if (!name) {
      setErrorMessage('Please enter your full name.');
    } else if (!name.includes(' ')) {
      setErrorMessage('Please enter your full name.');
    } else if (!title) {
      setErrorMessage('Please enter your job title.');
    } else if (!orgName) {
      setErrorMessage('Please enter your organization name.');
    } else if (orgName.length < 4) {
      setErrorMessage('Your organization name must be at least 4 characters.');
    } else {
      validated = true;
    }
    return validated;
  }

  const handleClick = async () => {

    if (!validateSignupForm()) return;
    setCreatingAccount(true);
    await createOrg({ user, orgName, title, name }).then(data => {
      setAccount({ name, title, orgName })
      console.log({ name, title, orgName });
      setErrorMessage('');
      // setCreatingAccount(false);
      navigate(homePath);
      window.location.reload('');
    }).catch((error) => {
      setErrorMessage(errorMessage);
      console.log('Error: Cannot sign up user.', error)
      setCreatingAccount(false);
      setFallback(true);
    })
  }

  useEffect(() => {
    return setCreatingAccount(false);
  }, []);


  return fallback ? <FallbackPage message={`We're sorry.  There was a problem creating your account.`} /> : (
    <div className="min-h-screen-adj flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Set up your account
          </h2>
        </div>
        <div className="mt-8 space-y-4">

          {/* Name */}
          <div>
            <label htmlFor="name" className="text-sm font-semibold">Full name</label>
            <input
              id="name"
              name="name"
              type="text"
              autoComplete="name"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Bruce Wayne"
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            />
          </div>

          {/* Title */}
          <div>
            <label htmlFor="title" className="text-sm font-semibold">Title</label>
            <input
              id="title"
              name="title"
              type="text"
              autoComplete="title"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="CEO"
              value={title}
              onChange={(e) => { setTitle(e.target.value) }}
            />
          </div>

          {/* Organization name */}
          <div>
            <label htmlFor="orgName" className="text-sm font-semibold">Organization name</label>
            <input
              id="orgName"
              name="orgName"
              type="text"
              autoComplete="organization"
              required
              className="rounded appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-300 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
              placeholder="Wayne Industries"
              value={orgName}
              onChange={(e) => { setOrgName(e.target.value) }}
            />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => { handleClick() }}
              disabled={creatingAccount}
            >
              {creatingAccount ? spinnerIcon :
                (<span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {chevronRightIcon}
                </span>)
              }
              {creatingAccount ? '' : 'Next'}
            </button>
          </div>

          {!errorMessage ? '' : <label className="mt-2 text-blue-500 text-sm flex flex-column items-center"><span className="mr-2">{exclamationIcon}</span> {errorMessage}</label>}
        </div>
      </div>
    </div>
  )
}

export default CreateOrgPage;