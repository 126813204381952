import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {ToastContainer, toast} from 'react-toastify';
import { returnPublishedAssessmentUrl } from '../helpers/urls';

import { clipboardIcon } from "../helpers/icons";
import 'react-toastify/dist/ReactToastify.css';


const PublishedModal = ({ open, setOpen, assessmentId }) => {

  const cancelButtonRef = useRef(null);
  const notify = () => toast("Copied link");
  const publishedAssessmentUrl = returnPublishedAssessmentUrl({assessmentId});

  const copyLink = () => {
    navigator.clipboard.writeText(publishedAssessmentUrl);
    notify();
  }


  return (
    <div>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen-adj pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:justify-center">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-center text-gray-900">
                      Send this link to candidates
                    </Dialog.Title>
                    <div className="mt-4 w-96">
                      <div className="text-sm font-semibold py-1 text-gray-700">Link to take assessment</div>
                      <button 
                        className="border rounded-lg px-1 flex justify-between items-center w-full hover:bg-gray-100"
                        onClick={() => {copyLink()}}
                      >
                        <div className="pl-1 my-1 text-sm text-gray-500">{publishedAssessmentUrl}</div>
                        <div className="px-1 border-l">{clipboardIcon}</div>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    <ToastContainer 
      hideProgressBar={true}
      autoClose={2000}
    />
    </div>
  )
}

export default PublishedModal;